import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import ScreenNav from "../../components/SignUp/ScreenNav";
import Commment from "../../components/SelectDevice/Comments";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import MobileNav from "../../../components/address/MobileNav";
import Issues from "../../components/Issues/Issues";
import imageUrl from "../../../components/category/img/Featured icon(4).png";
import OtpVerificationModal from "./OtpVerificationModal"; // Ensure you import the modal component
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Extract device data from the location state
  const { brand, model, value, purchaseDuration } = state || {};

  const handleGoBack = () => {
    navigate(-1);
  };

  const handlePhoneChange = () => {
    navigate("/care/select-device");
  };

  const handleSubmit = async () => {
    // Define the new regex pattern for email or phone number validation
    const regex =
      /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|9715\d{8})$/;

    if (!emailOrPhone) {
      setError("Please enter your email or phone number.");
      return;
    }

    if (!regex.test(emailOrPhone)) {
      toast.error("Invalid email or phone number");
      return;
    }

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/send-otp`,
      params: { email: emailOrPhone },
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      // console.log('OTP sent:', data);

      if (data.statusCode === 400) {
        setErrorMessage("Failed to send OTP. Try with email"); // Set the error message
        return; // Stop further execution
      }

      setErrorMessage("");
      setEmail(emailOrPhone);
      setMobileNumber(emailOrPhone);
      setIsModalOpen(true); // Open the OTP modal on successful API call
    } catch (error) {
      console.error("Failed to send OTP:", error);
      if (error.response && error.response.status === 400) {
        toast.error(
          "Invalid Mobile or Email. Please check your input and try again."
        );
      } else {
        setError("Failed to send OTP. Please try again.");
      }
    }

    sessionStorage.setItem("email", emailOrPhone);
  };

  const handleVerifyOtp = async (otp) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/verify-otp`,
      headers: { "content-type": "application/json", accept: "*/*" },
      data: { otp: otp, email: email },
    };

    try {
      const { data } = await axios.request(options);

      // Check for invalid OTP response
      if (data.statusCode === 400 && data.msg === "Invalid OTP") {
        toast.error("Invalid OTP. Please try again.");
        return; // Stop further execution if OTP is invalid
      }

      const authToken = data?.data?.acessToken; // Ensure you are using the correct field for the token

      // Store the token in sessionStorage
      sessionStorage.setItem("authToken", authToken);

      // Navigate to care plan after successful OTP verification
      navigate("/care/estimated-price", {
        state: { brand, model, value, purchaseDuration },
      });
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      toast.error("OTP verification failed. Please try again.");
    }
  };

  const handleResendOtp = async () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/send-otp`,
      params: { email: emailOrPhone },
      headers: { accept: "*/*" },
    };

    try {
      await axios.request(options);
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (emailOrPhone) {
      // Define regex patterns for validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^9715\d{8}$/; // Adjusted pattern to match +9715XXXXXXXX format

      if (emailPattern.test(emailOrPhone)) {
        sessionStorage.setItem("userEmail", emailOrPhone);
      } else if (phonePattern.test(emailOrPhone)) {
        sessionStorage.setItem("userMobileNumber", emailOrPhone);
      }
    }
  }, [emailOrPhone]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="bg-[#F9FAFB] sm:mx-[60px] pt-[16px] px-[30px] pb-[90px] min-h-[680px] rounded-b-[40px] ">
        <div>
          <Navbar />
        </div>
        <div className="lg:hidden flex justify-center items-center my-8">
          <MobileNav />
        </div>
        <button
          onClick={handleGoBack}
          className="hidden ml-[48px] my-[60px] px-4 mb-4 py-[6px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
        >
          <span className="">
            <MdKeyboardArrowLeft />
          </span>
          <span className="font-bold whitespace-nowrap font-plus-jakarta-sans text-[14px] leading-[20px]">
            Go Back
          </span>
        </button>
        <div className="grid sm:grid-cols-6 grid-cols-1 justify-center">
          <div className="col-span-4 sm:ml-[48px] ">
            <div className="text-left">
              <h1 className="sm:text-4xl text-3xl font-plus-jakarta-sans text-[#101828] sm:text-center text-left lg:text-left font-bold leading-[44px] max-w-[300px] lg:max-w-[500px]">
                Sign up to continue
              </h1>
              <p className="text-gray-500 font-plus-jakarta-sans mt-[8px]">
                By signing up, you can view the order <br /> details, track and
                get prompt updates.
              </p>
            </div>
            <div className="mt-[32px] sm:mr-[32px] sm:max-w-[400px]">
              <div className="w-full flex flex-col items-start gap-4 p-6 bg-white border border-gray-300 rounded-lg">
                <div className="w-full flex flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <label className="text-[#344054] text-[14px] font-[500]">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                      className="w-full h-11 p-2 rounded-md border border-gray-300 placeholder-[#667085] text-[16px] leading-[24px] focus:outline-none"
                    />
                    {errorMessage && (
                      <span className="text-red-500 text-sm mt-1">
                        {errorMessage}
                      </span> // Display the error message
                    )}
                  </div>
                </div>
                <button
                  onClick={handleSubmit}
                  className="w-full h-11 flex items-center justify-center bg-yellow-400 text-black font-bold rounded-md mt-5 font-plus-jakarta-sans"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-2 sm:border-l sm:px-[42px]">
            <div className="lg:block hidden self-center mb-[-20px]">
              <ScreenNav />
            </div>
            <div className="pt-4 pb-8 border-b">
              <div className="flex flex-row justify-between sm:ml-12 lg:ml-0 mt-4">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                  Your Device
                </p>
                <button
                  onClick={handlePhoneChange}
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                >
                  Change
                </button>
              </div>
              <div className="mt-3">
                <Issues
                  title={brand || "Brand Name"}
                  brand={brand || "Brand Name"}
                  model={model || "Model Name"}
                  price={value || "Device Value"}
                  purchaseYear={purchaseDuration || "N/A"}
                />
              </div>
            </div>
            <Commment />
          </div>
        </div>
      </div>
      <div className="sm:mx-[50px]">
        <Footer />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-30">
          <OtpVerificationModal
            onClose={handleCloseModal}
            email={email}
            mobileNumber={mobileNumber}
            onVerify={handleVerifyOtp}
            onResend={handleResendOtp}
          />
        </div>
      )}
    </div>
  );
}

export default Signup;
