import React, { useEffect } from "react";
import Integration from "../components/signup/Integration";
import Footer from "../components/Footer";

const Singup = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="sm:mx-[50px] overflow-hidden">
      <Integration />
      <Footer />
    </div>
  );
};

export default Singup;
