import React, { useRef, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Spin } from "antd";
import "react-toastify/dist/ReactToastify.css";
import InboxIcon from "./assets/Featured icon.png";
import CloseIcon from "./assets/x-close.png";

const OtpVerificationModal = ({
  onClose,
  email,
  mobileNumber,
  onVerify,
  onResend,
}) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(120);
  const [verifying, setVerifying] = useState(false);

  const emailOrPhoneRegex =
    /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|7158\d{7})$/;

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === "Enter" && index === otp.length - 1) {
      handleVerifyClick();
    }
  };

  const handleVerifyClick = () => {
    const otpString = otp.join("");
    if (validateEmailOrPhone(email) || validateEmailOrPhone(mobileNumber)) {
      setVerifying(true);
      onVerify(otpString);
    } else {
      toast.error("Invalid email or mobile number");
    }
  };

  const handleResendClick = () => {
    onResend();
    setResendDisabled(true);
    setTimer(120); // Reset timer
    startCountdown();

    // Clear the OTP input fields and reset the otp state
    setOtp(new Array(6).fill(""));
    inputRefs.current.forEach((input) => (input.value = ""));
  };

  const startCountdown = () => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          setResendDisabled(false);
          clearInterval(countdown);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const validateEmailOrPhone = (input) => {
    return emailOrPhoneRegex.test(input);
  };

  useEffect(() => {
    inputRefs.current[0].focus();
    startCountdown();
    return () => clearInterval(startCountdown);
  }, []);

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(paste)) {
      const newOtp = paste.split("");
      setOtp(newOtp);
      newOtp.forEach((char, index) => {
        inputRefs.current[index].value = char;
      });
      inputRefs.current[5].focus();
    }
    e.preventDefault();
  };

  return (
    <div className="w-full h-auto bg-white rounded-md  p-6 mx-auto my-4 max-w-[400px] ">
      <div className="w-full flex justify-between items-center mb-4">
        <img src={InboxIcon} alt="Inbox Icon" className="w-12 h-12 mx-auto" />
        <img
          src={CloseIcon}
          alt="Close Icon"
          className="w-8 h-8 cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="text-center">
        <h2 className="text-lg font-bold font-inter text-[#101828]">
          Please check your email
        </h2>
        <p className="text-sm text-[#475467] mt-2">
          We've sent a code to{" "}
          <span className="font-bold font-plus-jakarta-sans">{email}</span>
        </p>
      </div>
      <div className="mt-6 flex md:mr-8 justify-center">
        <div className="flex gap-2">
          {[0, 1, 2, 3, 4, 5].map((_, index) => (
            <input
              key={index}
              type="number"
              maxLength="1"
              className="w-12 h-12 p-4 rounded-lg border-2 foucus:outline-none border-[#E4B703] bg-white shadow-sm text-center"
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
            />
          ))}
        </div>
      </div>
      <p className="mt-4 text-center md:mr-20 text-sm font-plus-jakarta-sans text-[#475467]">
        Didn’t get a code?
        <span
          className={`cursor-pointer underline ${
            resendDisabled ? "text-gray-500" : "text-blue-500 font-bold"
          }`}
          onClick={resendDisabled ? null : handleResendClick}
        >
          Click to resend.
        </span>{" "}
        {resendDisabled && `(${timer}s)`}
      </p>
      <div className="mt-6 flex justify-center gap-3">
        <button
          className="w-40 h-11 p-2.5 rounded-md border border-[#D0D5DD] bg-white shadow-sm font-bold"
          onClick={onClose}
          disabled={verifying}
        >
          Cancel
        </button>
        <button
          className="w-40 h-11 p-2.5 rounded-md border border-[#E4B703] bg-[#FBC903] shadow-sm font-bold flex items-center justify-center"
          onClick={handleVerifyClick}
          disabled={verifying}
        >
          {verifying ? (
            <>
              <Spin size="small" className="mr-2" />
              Verifying...
            </>
          ) : (
            "Verify"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OtpVerificationModal;
