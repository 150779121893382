import React from "react";
import Integration from "./Integration";
import Footer from "../Footer";

const Booking = () => {
  return (
    <div className="sm:mx-[50px]">
      <Integration />
      <Footer />
    </div>
  );
};

export default Booking;
