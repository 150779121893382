import React, { useState, useEffect } from "react";
import axios from "axios";
import Comment from "../../components/SelectDevice/Comments";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import MobileNav from "../../components/CareplanConfirmed/Address/MobileNav";
import ScreenNav from "../../components/CareplanConfirmed/Address/ScreenNav";
import crossIcon from "./cross.png"; // Ensure the crossIcon image is in the correct path
import Loader from "../../../components/loader/Loader";

function CareplanConfirmed() {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [additionalResponse, setAdditionalResponse] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isError, setIsError] = useState(false); // State to track error
  const [loading, setLoading] = useState(true); // State for loader
  const [isPaymentFailed, setIsPaymentFailed] = useState(false); // State to track payment status

  useEffect(() => {
    const bookingId = new URLSearchParams(window.location.search).get(
      "booking_id"
    );
    if (!bookingId) {
      setIsError(true);
      setLoading(false); // Stop loader if no booking ID
      return;
    }

    const fetchBookingDetails = async () => {
      const authToken = sessionStorage.getItem("authToken");

      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}:9009/payment-service/api/payment/details`,
        params: { bookingId },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const { data } = await axios.request(options);
        setBookingDetails(data.bookingDetails);
        setAdditionalResponse(data.additionalResponse);
        setPaymentDetails(data.paymentDetails);

        // Determine if payment has failed or is canceled
        if (
          data?.paymentDetails.paymentStatus === "Failed" ||
          data?.paymentDetails.paymentStatus === "CANCELLED" ||
          data?.paymentDetails.paymentStatus === "DECLINED" ||
          data?.paymentDetails.paymentStatus === "ABONDONED"
        ) {
          setIsPaymentFailed(true);
        }
      } catch (error) {
        console.error(error);
        setIsError(true); // Set error state to true if the request fails
      } finally {
        setLoading(false); // Stop loader after request completes
      }
    };

    fetchBookingDetails();
  }, []);

  const storage = sessionStorage.getItem("careStorageName");

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-[#F9FAFB] sm:mx-[60px] pt-[16px] px-[30px] pb-[90px] min-h-[680px] rounded-b-[40px]">
          <Navbar />
          <div className="lg:hidden flex justify-center items-center my-8">
            <MobileNav />
          </div>
          <div className="grid sm:grid-cols-6 grid-cols-1 justify-center mt-[48px]">
            {isError ? (
              <div className="flex flex-col min-h-screen bg-[#F9FAFB] col-span-4 p-7 px-8">
                <img
                  src={crossIcon}
                  alt="Error Icon"
                  className="w-16 h-16 mb-4"
                />
                <h2 className="text-2xl font-bold text-[#101828]">
                  Invalid Booking ID
                </h2>
                <p className="text-gray-500 mt-2 text-left">
                  We couldn't find a match for the Booking ID you entered.
                  Please double-check the ID and try again.
                </p>
              </div>
            ) : (
              <div className="col-span-4 sm:ml-[48px]">
                <div className="text-left">
                  <h1 className="sm:text-4xl text-2xl font-plus-jakarta-sans text-[#101828] sm:text-center lg:text-left font-bold leading-[44px] max-w-[300px] lg:max-w-[500px]">
                    {isPaymentFailed
                      ? "Payment Failed"
                      : "Care Plan Confirmation"}
                  </h1>
                  <p className="text-gray-500 font-plus-jakarta-sans mt-[8px]">
                    {isPaymentFailed
                      ? "There was an issue with your payment."
                      : "Confirmation mail has been sent to your email and phone number. Please check."}
                  </p>
                </div>
                <div className="flex flex-col gap-[24px] mt-[32px] mr-[20px]">
                  <div className="p-[32px] border rounded-[16px]">
                    <div className="text-start">
                      <h3 className="sm:text-[28px] text-2xl font-bold leading-[24px] mb-3">
                        Payment Details
                      </h3>
                      <p className="text-[#475467] text-sm">
                        Payment Status: {paymentDetails?.paymentStatus || "N/A"}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Payment Transaction Reference:{" "}
                        {paymentDetails?.transactionReference || "N/A"}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Total Amount:{" "}
                        {paymentDetails?.totalAmount
                          ? `AED ${paymentDetails.totalAmount}`
                          : ""}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Discount: {paymentDetails?.couponCode || "N/A"}
                      </p>
                      {!isPaymentFailed && (
                        <p className="text-[#475467] text-sm">
                          Amount Collected:{" "}
                          {paymentDetails?.amountCollected
                            ? `AED ${paymentDetails.amountCollected}`
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="p-[32px] border rounded-[16px]">
                    <div className="text-start">
                      <h3 className="sm:text-[28px] text-2xl font-bold leading-[24px] mb-3">
                        Care Plan Details
                      </h3>
                      <p className="text-[#475467] text-sm">
                        Care Policy number:{" "}
                        <span className="font-bold">
                          {bookingDetails?.policyNumber || "N/A"}
                        </span>
                      </p>
                      <p className="text-[#475467] text-sm">
                        Care Plan: {bookingDetails?.carePlan || "N/A"}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Care Description:{" "}
                        {bookingDetails?.careDesc ? (
                          <ul className="mt-2 text-sm font-normal text-[#475467] list-disc pl-5">
                            {bookingDetails.careDesc
                              .split("\n")
                              .map((item, idx) => (
                                <li key={idx}>{item}</li>
                              ))}
                          </ul>
                        ) : (
                          <p className="mt-2 text-sm font-normal text-[#475467]">
                            N/A
                          </p>
                        )}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Brand & Model:{" "}
                        {`${bookingDetails?.brand || "N/A"} ${
                          bookingDetails?.model || "N/A"
                        }`}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Device Value & Storage:{" "}
                        {bookingDetails?.deviceValue
                          ? `AED ${bookingDetails.deviceValue}`
                          : "Device Value"}{" "}
                        & {storage || "N/A"}
                      </p>
                      <div>
                        <h4 className="text-[#B28F02] font-bold mt-1">
                          Deductibles:
                        </h4>
                        <ul className="mt-1 text-[#475467] text-sm pl-4 list-disc">
                          {bookingDetails?.deductables?.length > 0 ? (
                            bookingDetails.deductables.map(
                              (deductible, index) => (
                                <li key={index}>
                                  <strong>
                                    {deductible.response.deductableName}
                                  </strong>
                                  : AED {Math.round(deductible.price)}
                                </li>
                              )
                            )
                          ) : (
                            <li>No Deductibles</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="p-[32px] border rounded-[16px]">
                    <div className="text-start">
                      <h3 className="sm:text-[28px] text-2xl font-bold leading-[24px] mb-3">
                        Additional Information
                      </h3>
                      <p className="text-[#475467] text-sm">
                        Name: {additionalResponse?.name || "N/A"}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Email ID: {additionalResponse?.email || "N/A"}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Mobile: {additionalResponse?.phoneNumber || "N/A"}
                      </p>
                      <p className="text-[#475467] text-sm">
                        Address: {additionalResponse?.address || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-span-2 sm:border-l sm:px-[42px]">
              <div className="lg:block hidden self-center mb-[-20px]">
                <ScreenNav />
              </div>
              <Comment />
            </div>
          </div>
        </div>
      )}
      <div className="sm:mx-[50px]">
        <Footer />
      </div>
    </div>
  );
}

export default CareplanConfirmed;
