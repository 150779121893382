import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import ScreenNav from "../../components/EstimatedPrice/ScreenNav";
import Comment from "../../components/SelectDevice/Comments";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import Issues from "../../components/Issues/Issues";
import MobileNav from "../../components/EstimatedPrice/MobileNav";
import { FiChevronDown, FiSearch } from "react-icons/fi";

function EstimatedPrice() {
  const [carePlans, setCarePlans] = useState([]);
  const [collapsedIndex, setCollapsedIndex] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [deductibles, setDeductibles] = useState([]);
  const [selectedDeductible, setSelectedDeductible] = useState({});
  const [updatedPrices, setUpdatedPrices] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { state = {} } = location;

  // const { brand, model, value, purchaseDuration } = state;

  const category = sessionStorage.getItem("careCategoryName");
  const Brand = sessionStorage.getItem("carebrandName");
  const Model = sessionStorage.getItem("caremodelName");
  const Value = sessionStorage.getItem("caredeviceValue");
  const storage = sessionStorage.getItem("careStorageName");
  const purchaseDuration = sessionStorage.getItem("carePurchaseDuration");

  const handlePhoneChange = () => {
    navigate("/care/select-device");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const price = sessionStorage?.getItem("caredeviceValue");
        const categorySkuCode = sessionStorage?.getItem("carecategorySkuCode");
        const brandSkuCode = sessionStorage?.getItem("carebrandSkuCode");
        const modelSkuCode = sessionStorage?.getItem("caremodelSkuCode");
        const storageSkuCode = sessionStorage?.getItem("careStorageSkuCode");
        const token = sessionStorage?.getItem("authToken");

        // if (
        //   !price ||
        //   !categorySkuCode ||
        //   !brandSkuCode ||
        //   !modelSkuCode ||
        //   !storageSkuCode ||
        //   !token
        // ) {
        //   return;
        // }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}:8070/master-service/care/calculate-form-category-brand-model`,
          {
            params: {
              categorySku: categorySkuCode,
              modelSku: modelSkuCode,
              brandSku: brandSkuCode,
              storageSku: storageSkuCode,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
              accept: "*/*",
            },
          }
        );

        const carePlansData = response?.data?.body;
        setCarePlans(carePlansData);

        // console.log("Care Plans:", carePlansData);

        if (carePlansData.length === 0) {
          console.error("No care plans found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate("/care/select-device");
  };

  const toggleCollapse = (index) => {
    setCollapsedIndex(collapsedIndex === index ? null : index);
    setDropdownVisible(null);
    setSearchValue("");
  };

  const toggleDropdown = async (index, skuCode) => {
    if (dropdownVisible === index) {
      setDropdownVisible(null);
    } else {
      setDropdownVisible(index);
      try {
        const plan = carePlans[index];

        if (plan.careName === "Re-NIW Basic") {
          setDeductibles([]);
          return;
        }

        const options = {
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/detucatables/find-deductbales-from-category-brand-model-care`,
          params: {
            careSku: skuCode,
            categorySku: sessionStorage?.getItem("carecategorySkuCode"),
            brandSku: sessionStorage?.getItem("carebrandSkuCode"),
            modelSku: sessionStorage?.getItem("caremodelSkuCode"),
          },
          headers: {
            accept: "*/*",
          },
        };
        const response = await axios.request(options);
        const deductibles = response?.data?.data;
        setDeductibles(deductibles);
      } catch (error) {
        console.error("Error fetching deductibles:", error);
      }
    }
  };

  const handleProceedToCheckout = () => {
    // Save the price of the selected plan to session storage
    if (collapsedIndex !== null) {
      const selectedPlan = carePlans[collapsedIndex];
      const price =
        updatedPrices[collapsedIndex] !== undefined
          ? updatedPrices[collapsedIndex]
          : selectedPlan.price;

      sessionStorage.setItem("cardPrice", price);

      // Save the careName and careSkuCode to session storage
      sessionStorage.setItem("careName", selectedPlan.careName);
      sessionStorage.setItem("planDesc", selectedPlan.description);
      sessionStorage.setItem("storage", selectedPlan.careStorageName);
      sessionStorage.setItem("purchaseDuration", selectedPlan.purchaseDuration);
      sessionStorage.setItem("careSkuCode", selectedPlan.skuCode);
    }
    navigate("/care/address");
  };

  return (
    <div>
      <div className="bg-[#F9FAFB] sm:mx-[60px] pt-[16px] sm:px-[30px] px-[25px] pb-[90px] min-h-[680px] rounded-b-[40px]">
        <Navbar />
        <div className="lg:hidden flex justify-center items-center my-8">
          <MobileNav />
        </div>
        <button
          onClick={handleGoBack}
          className="hidden ml-[48px] my-[60px] px-4 mb-4 py-[6px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
        >
          <span>
            <MdKeyboardArrowLeft />
          </span>
          <span className="font-bold whitespace-nowrap font-plus-jakarta-sans text-[14px] leading-[20px]">
            Go Back
          </span>
        </button>
        <div className="grid sm:grid-cols-6 grid-cols-1 justify-center">
          <div className="col-span-4 sm:ml-[48px]">
            <div className="text-left sm:mt-[24px]">
              <h1 className="sm:text-4xl text-3xl whitespace-nowrap font-plus-jakarta-sans sm:text-[36px] text-[#101828] sm:text-center text-left lg:text-left font-bold leading-[44px] max-w-[300px] lg:max-w-[500px]">
                Yearly Plan
              </h1>
            </div>
            <div className="sm:mt-[32px] max-w-[400px]">
              <div className="sm:gap-8 opacity-100 flex flex-col items-start lg:mr-20">
                {carePlans?.map((plan, index) => (
                  <div
                    key={index}
                    className={`h-auto px-8 py-6 bg-white rounded-2xl shadow-md mt-4 border ${
                      collapsedIndex === index
                        ? "border-[#FBC903]"
                        : "border-[#EAECF0]"
                    } sm:min-w-[580px] w-full`}
                  >
                    <div
                      className="w-full h-auto sm:pb-2 cursor-pointer"
                      onClick={() => toggleCollapse(index)}
                    >
                      <div className="w-full h-auto grid sm:grid-cols-2 grid-cols-1 sm:gap-[200px] items-start">
                        <div className="sm:w-[353px] h-auto flex flex-col">
                          <div className="w-auto h-auto flex items-center gap-4">
                            <h3 className="sm:text-xl font-semibold font-plus-jakarta-sans text-[#101828]">
                              {plan.careName}
                            </h3>
                            <span className="text-center whitespace-nowrap text-sm font-medium text-[#B28F02] bg-[#FFFAE6] border border-[#FDE68B] px-3 rounded-full py-1">
                              Best Price
                            </span>
                          </div>
                          <ul className="mt-3 sm:whitespace-nowrap sm:text-md text-sm font-normal font-plus-jakarta-sans text-[#475467]">
                            {plan.description.split("\n").map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="text-right flex gap-1">
                          <span className="block sm:text-3xl text-2xl lg:text-[34px] font-bold text-[#101828]">
                            {updatedPrices[index] !== undefined
                              ? `AED ${Math.round(updatedPrices[index])}`
                              : `AED ${Math.round(plan.price)}`}
                          </span>
                        </div>
                      </div>
                    </div>
                    {collapsedIndex === index &&
                      plan.careName !== "Re-NIW Basic" && (
                        <div className="mt-4 relative pb-6 border-b border-[#EAECF0]">
                          <div className="relative">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(index, plan.skuCode);
                              }}
                              className="w-full border border-gray-300 rounded-md py-[9px] pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                            >
                              {searchValue || "View Deductibles"}
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <FiChevronDown className="absolute inset-0 ml-[-12px] mt-3 h-5 w-5 text-gray-400" />
                              </div>
                            </div>
                            {dropdownVisible === index && (
                              <div className="mt-2">
                                <ul className="w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-auto">
                                  {deductibles?.length > 0 ? (
                                    deductibles?.map((deductible, idx) => (
                                      <li
                                        key={idx}
                                        className="px-5 py-2 cursor-not-allowed text-black"
                                      >
                                        <span>
                                          {deductible?.deductableName}
                                        </span>
                                        <span className="ml-2">
                                          {deductible?.percenatgeValue !== 0
                                            ? `${deductible?.percenatgeValue} %`
                                            : `${deductible?.absoluteValue} %`}
                                        </span>
                                        <hr className="mt-3 border-x border-gray-200" />
                                      </li>
                                    ))
                                  ) : (
                                    <li className="px-3 py-2 flex items-center justify-start text-gray-500">
                                      No results found
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    {collapsedIndex === index && (
                      <div className="mt-8 mb-3 lg:mb-0 lg:mt-4 flex flex-col items-center">
                        <button
                          className="w-full px-4 py-3 flex items-center justify-center bg-[#FBC903] border border-[#E4B703] rounded-md shadow-sm text-black font-bold text-[16px] leading-[24px]"
                          onClick={handleProceedToCheckout}
                        >
                          Proceed to Checkout
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <p className="text-[18px] mt-6 font-semibold">
              *Each plan includes a 30-day cooling-off period for your
              convenience.
            </p>
          </div>
          <div className="col-span-2 sm:border-l sm:px-[42px]">
            <div className="lg:block hidden self-center mb-[-20px]">
              <ScreenNav />
            </div>
            <div className="pt-4 pb-8 border-b">
              <div className="flex flex-row justify-between sm:ml-12 lg:ml-0 mt-4">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                  Your Device
                </p>
                <button
                  onClick={handlePhoneChange}
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                >
                  Change
                </button>
              </div>
              <div className="mt-3">
                <Issues
                  title={category || "Category Name"}
                  brand={Brand || "Brand Name"}
                  model={Model || "Model Name"}
                  price={Value || "Device Value"}
                  storage={storage || "Device Storage"}
                />
              </div>
            </div>
            <Comment />
          </div>
        </div>
      </div>
      <div className="sm:mx-[50px]">
        <Footer />
      </div>
    </div>
  );
}

export default EstimatedPrice;
