import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import SignupForm from "../components/signup/SignupForm";
import Footer from "../components/Footer";
import OtpVerificationModal from "../components/signup/OtpVerificationModal";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignupPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    if (authToken) {
      // console.log('Auth token found in sessionStorage:', authToken);
      fetchEstimatedBookingFee(authToken);
    }
  }, []);

  const handleGetStarted = (emailOrPhone) => {
    setEmailOrPhone(emailOrPhone);
    setEmail(emailOrPhone);
    setMobileNumber(emailOrPhone);

    // Only open the modal if the statusCode is not 400
    setIsModalOpen(true);
  };

  const handleVerifyOtp = async (otp) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/verify-otp`,
      headers: { "content-type": "application/json", accept: "*/*" },
      data: { otp: otp, email: emailOrPhone },
    };

    try {
      const { data } = await axios.request(options);
      if (data.statusCode === 400) {
        toast.error("Invalid OTP. Please try again.");
        return;
      }
      // console.log('OTP verified:', data);
      const authToken = data.data.acessToken; // Ensure you are using the correct field for the token
      // console.log('Auth token:', authToken);
      // Store the token in sessionStorage
      sessionStorage.setItem("authToken", authToken);

      // Fetch estimated booking fee
      await fetchEstimatedBookingFee(authToken);
      return { success: true };
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      return { success: false };
    }
  };

  const fetchEstimatedBookingFee = async (authToken) => {
    // console.log('Fetching estimated booking fee with authToken:', authToken);

    const categorySkuCode = sessionStorage.getItem("categorySkuCode");
    const defectSkuCode = sessionStorage.getItem("defectSkuCode");
    const brandSkuCode = sessionStorage.getItem("brandSkuCode");
    const modelSkuCode = sessionStorage.getItem("modelSkuCode");

    const priceOptions = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/calculate-price-from-category-brand-model-deffect`,
      params: {
        categorySkucode: categorySkuCode,
        defectSKucode: defectSkuCode,
        brandSkucode: brandSkuCode,
        modelSkucode: modelSkuCode,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        accept: "*/*",
      },
    };

    try {
      const { data: priceData } = await axios.request(priceOptions);
      // console.log('Fetched estimated booking fee:', priceData);
      navigateToBookingFee(authToken, priceData);
    } catch (error) {
      console.error("Failed to fetch estimated booking fee:", error);
      // alert('Failed to fetch estimated booking fee. Please try again later.');
    }
  };

  const navigateToBookingFee = (authToken, priceData) => {
    const state = {
      ...location.state,
      priceData,
    };
    // console.log('Navigating to booking fee page with state:', state);
    navigate("/repair/booking-fee", { state });
  };

  const handleResendOtp = async () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/send-otp`,
      params: { email: emailOrPhone },
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      // console.log(data);
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center p-4 xl:pr-[12rem] ">
      <SignupForm onGetStarted={handleGetStarted} />
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-30">
          <OtpVerificationModal
            onClose={handleCloseModal}
            email={email}
            mobileNumber={mobileNumber}
            onVerify={handleVerifyOtp}
            onResend={handleResendOtp}
          />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SignupPage;
