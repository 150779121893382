import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FiSearch, FiChevronRight } from "react-icons/fi";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Loader from "../loader/Loader";
import { toast, ToastContainer } from "react-toastify";

const DeviceSelection = ({ name, description, imageUrl }) => {
  const [brand, setBrand] = useState("");
  const [customBrand, setCustomBrand] = useState("");
  const [model, setModel] = useState("");
  const [customModel, setCustomModel] = useState("");
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedBrandSkuCode, setSelectedBrandSkuCode] = useState(null);
  const [selectedModelSkuCode, setSelectedModelSkuCode] = useState(null);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [loadingModels, setLoadingModels] = useState(false);
  const [error, setError] = useState(null);
  const [isBrandOthersSelected, setIsBrandOthersSelected] = useState(false);
  const [isModelOthersSelected, setIsModelOthersSelected] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const categorySkuCode = sessionStorage.getItem("categorySkuCode");
  const defectSkuCode = sessionStorage.getItem("defectSkuCode");

  useEffect(() => {
    async function fetchBrands() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}:8070/master-service/brands/find-by-category-deffect`,
          {
            params: {
              categorySkucode: categorySkuCode,
              deffectSkucode: defectSkuCode,
            },
          }
        );
        setBrands(response.data);
        setFilteredBrands(response.data);
      } catch (error) {
        setError(error.message);
        toast.error("Failed to fetch brands.");
      } finally {
        setLoadingBrands(false);
      }
    }

    fetchBrands();
  }, [categorySkuCode, defectSkuCode]);

  useEffect(() => {
    if (!selectedBrandSkuCode) return;

    async function fetchModels() {
      setLoadingModels(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}:8070/master-service/model/find-by-category-deffect-brand`,
          {
            params: {
              categorySkucode: categorySkuCode,
              deffectSkucode: defectSkuCode,
              brandSkucode: selectedBrandSkuCode,
            },
          }
        );
        setModels(response.data);
        setFilteredModels(response.data);
      } catch (error) {
        setError(error.message);
        toast.error("Failed to fetch models.");
      } finally {
        setLoadingModels(false);
      }
    }

    fetchModels();
  }, [selectedBrandSkuCode, categorySkuCode, defectSkuCode]);

  useEffect(() => {
    if (brand.toLowerCase() === "others") {
      setIsBrandOthersSelected(true);
    } else {
      setIsBrandOthersSelected(false);
    }
  }, [brand]);

  useEffect(() => {
    if (model.toLowerCase() === "others") {
      setIsModelOthersSelected(true);
    } else {
      setIsModelOthersSelected(false);
    }
  }, [model]);

  const handleBrandClick = (skuCode, brandName) => {
    sessionStorage.setItem("brandSkuCode", skuCode);
    sessionStorage.setItem("brandName", brandName);
    setSelectedBrandSkuCode(skuCode);
    setBrand(brandName);
    setIsBrandOthersSelected(brandName.toLowerCase() === "others");
  };

  const handleBrandChange = (e) => {
    const value = e.target.value;
    setBrand(value);
    setFilteredBrands(
      value.toLowerCase() === "others"
        ? []
        : brands.filter((b) =>
            b.name.toLowerCase().includes(value.toLowerCase())
          )
    );
    setModel("");
    setCustomModel("");
    setFilteredModels([]);
  };

  const handleCustomBrandChange = (e) => {
    setCustomBrand(e.target.value);
  };

  const handleModelChange = (e) => {
    const value = e.target.value;
    setModel(value);
    setFilteredModels(
      value.toLowerCase() === "others"
        ? []
        : models.filter((m) =>
            m.name.toLowerCase().includes(value.toLowerCase())
          )
    );
  };

  const handleCustomModelChange = (e) => {
    setCustomModel(e.target.value);
  };

  const handleModelClick = (name, skuCode) => {
    sessionStorage.setItem("modelSkuCode", skuCode);
    sessionStorage.setItem("modelName", name);
    setModel(name);
    setSelectedModelSkuCode(skuCode);
    // setFilteredModels(filteredModels.filter((m) => m.skuCode !== skuCode));
  };

  const handleBackClick = () => {
    navigate("/repair/defects", { state: { ...location.state } });
  };

  const handleContinueClick = () => {
    const finalBrand = isBrandOthersSelected ? customBrand : brand;
    const finalModel = isModelOthersSelected ? customModel : model;
    sessionStorage.setItem("brandName", finalBrand);
    sessionStorage.setItem("modelName", finalModel);
    navigate("/repair/sign-up", {
      state: {
        brandName: finalBrand,
        modelName: finalModel,
        name,
        description,
        imageUrl,
      },
    });
  };

  if (loadingBrands) {
    return (
      <div>
        <Loader loading={loadingBrands} />
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-10 xl:px-0 sm:mt-10 w-full sm:w-auto">
      <ToastContainer />
      <button
        onClick={handleBackClick}
        className="hidden sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 mb-4 w-28 h-9"
      >
        <MdKeyboardArrowLeft />
        <span className="font-bold text-sm">Go Back</span>
      </button>
      <div className="text-left mb-7">
        <h1 className="font-bold text-2xl sm:text-3xl text-[#101828]">
          Find your device
        </h1>
        <p className="text-gray-500 mt-4">
          Select your device that needs to be repaired.
        </p>
      </div>
      <div className="bg-white p-4 rounded-lg border mt-3 w-full sm:w-[420px]">
        <div className="mb-5">
          <label className="block text-gray-700 text-sm mb-2" htmlFor="brand">
            Select Brand of your Device
          </label>
          <div className="relative">
            <input
              type="text"
              id="brand"
              placeholder="eg- apple, samsung or type 'Others'"
              className="w-full border border-gray-300 rounded-md py-2 pl-3 pr-10 text-gray-700 focus:outline-none focus:shadow-outline"
              value={brand}
              onChange={handleBrandChange}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiChevronRight className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          {isBrandOthersSelected && (
            <div className="mt-4">
              <input
                type="text"
                placeholder="Enter your brand"
                className="w-full border border-gray-300 rounded-md py-2 pl-3 text-gray-700 focus:outline-none focus:shadow-outline"
                value={customBrand}
                onChange={handleCustomBrandChange}
              />
            </div>
          )}
          {/* Brand List */}
          <ul className="border border-gray-300 rounded-md max-h-40 overflow-auto mt-4 w-full">
            {filteredBrands.length > 0
              ? filteredBrands.map((b) => (
                  <li
                    key={b.skuCode}
                    className="px-5 py-2 mt-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleBrandClick(b.skuCode, b.name)}
                  >
                    {b.name}
                    <hr className="mt-3 border-t border-gray-200" />
                  </li>
                ))
              : !isBrandOthersSelected && (
                  <li className="px-3 py-2 flex items-center text-gray-500">
                    <FiSearch className="mr-2" />
                    No results found
                  </li>
                )}
          </ul>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm mb-2" htmlFor="model">
            Select your Model
          </label>
          <div className="relative mt-4">
            <input
              type="text"
              id="model"
              placeholder="eg- 13, 15pro max"
              className="w-full border border-gray-300 rounded-md py-2 pl-3 pr-10 text-gray-700 focus:outline-none focus:shadow-outline"
              value={model}
              onChange={handleModelChange}
              disabled={!selectedBrandSkuCode && !isBrandOthersSelected}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiChevronRight className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          {isModelOthersSelected && (
            <div className="mt-4">
              <input
                type="text"
                placeholder="Enter your model"
                className="w-full border border-gray-300 rounded-md py-2 pl-3 text-gray-700 focus:outline-none focus:shadow-outline"
                value={customModel}
                onChange={handleCustomModelChange}
              />
            </div>
          )}
          {!selectedModelSkuCode && (
            <ul className="border border-gray-300 rounded-md max-h-[240px] overflow-auto mt-4 w-full">
              {filteredModels.length > 0
                ? filteredModels.map((m) => (
                    <li
                      key={m.skuCode}
                      className="px-5 py-2 mt-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelClick(m.name, m.skuCode)}
                    >
                      {m.name}
                      <hr className="mt-3 border-t border-gray-200" />
                    </li>
                  ))
                : !isModelOthersSelected && (
                    <li className="px-3 py-2 flex items-center text-gray-500">
                      <FiSearch className="mr-2" />
                      No results found
                    </li>
                  )}
            </ul>
          )}
        </div>
        <div className="mt-6">
          <button
            className="w-full bg-yellow-400 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
            onClick={handleContinueClick}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeviceSelection;
