import React from "react";

function RequestDeletion() {
  return (
    <div class="font-sans text-black">
      <div class="max-w-[780px] mx-auto my-5 bg-white rounded-md shadow-lg border p-[40px]">
        <h1 class="text-left text-2xl mb-4">Request Account Deletion</h1>
        <p class="text-base leading-6 mb-4">
          We're sorry to see you go. To delete your account and all associated
          data, please follow the steps below:
        </p>

        <h2 class="text-xl mb-4">Instructions:</h2>
        <ul class="mb-5 ml-4">
          <li class="mb-2">
            1. Send an email to our <strong>support team</strong> at{" "}
            <a href="">support@gadgetsreborn.com</a>.
          </li>
          <li class="mb-2">
            2. Use the following subject line for your email: <br />
            <strong className="ml-4">
              "Request for Account Deletion - Gadgets Reborn"
            </strong>
            .
          </li>
          <li class="mb-2">3. Example Email Template:</li>
        </ul>

        <h2 class="text-base mb-2">Dear Gadgets Reborn Support Team,</h2>
        <p class="text-base leading-6 mb-2">
          I am writing to request the deletion of my user account associated
          with the email address [your email address] from your platform. Please
          remove all personal information, data, and account details related to
          this account as per your privacy policy.
        </p>
        <h2 class="text-base mb-2">Here are my account details:</h2>
        <p class="text-base leading-6 mb-2">
          Username/Email: [Your Username or Email Address]
        </p>
        <p class="text-base leading-6 mb-2">
          Registered Phone Number (if applicable): [Your Phone Number]
        </p>
        <p class="text-base leading-6 mb-2">
          Please confirm once my account has been deleted and my data has been
          removed from your system. Let me know if you need any additional
          information from my side to process this request.
        </p>
        <p class="text-base leading-6 mb-2">Thank you for your assistance.</p>
        <p className="text-base leading-6 mb-2">Best regards,</p>
        <p className="text-base leading-6 mb-2">[Your Name]</p>

        <ul class="mb-5">
          <li class="mb-2">
            4. Our team will process your request within 5-7 business days and
            confirm via email once your account has been deleted.
          </li>
        </ul>

        <p class="text-base text-black">
          <strong>
            Data Retention Notice: <br />
          </strong>{" "}
          Certain data, such as transaction history, may be retained for legal
          reasons. If applicable, we will notify you in the confirmation email.
        </p>
      </div>
    </div>
  );
}

export default RequestDeletion;
