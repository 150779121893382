import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function ContactUs() {
  return (
    <div>
      <div className="lg:mx-[40px]">
        <Navbar />
      </div>
      <div className="min-h-screen my-[40px]">
        <main className="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8">
          <section className="text-center mb-3">
            <h1 className="text-[38px] sm:text-[48px] font-semibold text-gray-900 text-start">
              Contact us
            </h1>
          </section>

          <section className="space-y-6">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                Need Assistance? We're Here to Help!
              </h2>
              <p className="mt-2">
                At Gadgets Reborn, customer satisfaction is our top priority.
                Whether you’re seeking product support, have questions about
                your order, or want to give feedback, our team is ready to
                assist you. We offer multiple support options for your
                convenience, so you can reach us in the way that suits you best.
              </p>
              <p className="mt-2">Get Support Your Way</p>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                WhatsApp Support:
              </h2>
              <p className="mt-2">
                Need fast assistance? Our customer support team is just a
                message away. Available from 9:00 AM to 5:30 PM, Monday to
                Saturday (GST). Contact us via WhatsApp at: +971 55 767 6010
                Quick, hassle-free, and direct communication at your fingertips.
              </p>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                Email Support:
              </h2>
              <p className="mt-2">
                For more detailed inquiries or feedback, send us an email at
                support@gadgetsreborn.com. Our team will respond within 24 hours
                to ensure your issues are promptly addressed.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                Live Chat:
              </h2>
              <p className="mt-2">
                Got a quick question while browsing? Use our live chat for
                instant help. Chat with a support specialist from 9:00 AM to
                5:30 PM (GST), Monday to Saturday.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                Our Location
              </h2>
              <p className="mt-2">
                Gadgets Reborn FZ LLC <br /> HD83C First Floor IN5 Tech Hub{" "}
                <br />
                Dubai Internet City, Dubai UAE
              </p>
            </div>
          </section>
        </main>
      </div>
      <div className="lg:mx-[40px]">
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
