import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import LandingPage from "./pages/LandingPage";
import RepairGadgetPage from "./pages/RepairGadgetPage";
import DefectsPage from "./pages/DefectsPage";
import BrandModelPage from "./pages/BrandModelPage";
import Singup from "./pages/Singup";
import BookingFeePage from "./components/bookingFee/Booking";
import AddressPage from "./pages/AddressPage";
import BookingConfirmPage from "./pages/BookingConfirmPage";
import CareFlowLandingPage from "../src/care-flow/pages/LandingPage/LandingPage";
import DeviceSelection from "../src/care-flow/pages/SelectDevice/SelectDevice";
import Signup from "./care-flow/pages/Signup/Signup";
import EstimatedPrice from "./care-flow/pages/EstimatedPrice/EstimatedPrice";
import SessionStorageClearer from "./components/SessionStorageClearer";
import Address from "./care-flow/pages/Address/Address";
import CareplanConfirmed from "./care-flow/pages/CareplanConfirmed/CareplanConfirmed";
import UserProfile from "./user/UserProfile/UserProfile";
import RepairHistory from "./user/RepairHistory/RepairHistory";
import CareHistory from "./user/CareHistory/CareHistory";
import BookingDetails from "./user/BoookingDetails/BookingDetails";
import UserLogin from "./components/Login/UserLogin";
import AuthGuard from "./components/AuthGuard";
import CareDetailed from "./user/CareDetailed/CareDetailed";
import Banner from "./user/Banner/Banner";
import GadgetHeros from "./pages/GadgetHeros";
import GrOutpost from "./pages/GrOutpost";
import Blog from "./pages/Blog";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import RequestDeletion from "./pages/RequestDeletion";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = sessionStorage.getItem("authToken");
  console.log(isAuthenticated);
  return !isAuthenticated || isAuthenticated === "undefined" ? (
    <Navigate to="/user/login" />
  ) : (
    element
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <SessionStorageClearer />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Home</title>
              </Helmet>
              <LandingPage />
            </>
          }
        />
        <Route
          path="/repair"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Get Your Device Repaired</title>
              </Helmet>
              <RepairGadgetPage />
            </>
          }
        />
        <Route
          path="/repair/defects"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Select Defects</title>
              </Helmet>
              <DefectsPage />
            </>
          }
        />
        <Route
          path="/repair/mobile"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Select Defects</title>
              </Helmet>
              <DefectsPage />
            </>
          }
        />
        <Route
          path="/repair/tablet"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Select Defects</title>
              </Helmet>
              <DefectsPage />
            </>
          }
        />
        <Route
          path="/repair/laptop"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Select Defects</title>
              </Helmet>
              <DefectsPage />
            </>
          }
        />
        <Route
          path="/repair/playstation"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Select Defects</title>
              </Helmet>
              <DefectsPage />
            </>
          }
        />
        <Route
          path="/repair/category"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Select Category</title>
              </Helmet>
              <BrandModelPage />
            </>
          }
        />
        <Route
          path="/repair/sign-up"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Sign Up</title>
              </Helmet>
              <Singup />
            </>
          }
        />
        <Route
          path="/repair/booking-fee"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Booking Fee</title>
              </Helmet>
              <BookingFeePage />
            </>
          }
        />
        <Route
          path="/repair/address"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Address</title>
              </Helmet>
              <AddressPage />
            </>
          }
        />
        <Route
          path="/repair/booking-confirmed"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Booking Confirmed</title>
              </Helmet>
              <BookingConfirmPage />
            </>
          }
        />

        <Route
          path="/blogs"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Blogs</title>
              </Helmet>
              <Blog />
            </>
          }
        />

        <Route
          path="/blogs/1"
          element={
            <>
              <Blog1 />
            </>
          }
        />

        <Route
          path="/blogs/2"
          element={
            <>
              <Blog2 />
            </>
          }
        />
        <Route
          path="/request-deletion"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Request Deletion</title>
              </Helmet>
              <RequestDeletion />
            </>
          }
        />

        {/* Care flow Routes */}
        <Route
          path="/care"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Get Your Device Protected</title>
              </Helmet>
              <CareFlowLandingPage />
            </>
          }
        />
        <Route
          path="/care/select-device"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Care Flow - Select Device</title>
              </Helmet>
              <DeviceSelection />
            </>
          }
        />
        <Route
          path="/care/sign-up"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Care Flow - Sign Up</title>
              </Helmet>
              <Signup />
            </>
          }
        />
        <Route
          path="/care/estimated-price"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Care Flow - Estimated Price</title>
              </Helmet>
              <ProtectedRoute element={<EstimatedPrice />} />
            </>
          }
        />
        <Route
          path="/care/address"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Care Flow - Select Address</title>
              </Helmet>
              <Address />
            </>
          }
        />
        <Route
          path="/care/booking-confirmed"
          element={
            <>
              <Helmet>
                <title>Gadgets Reborn - Care Flow - Select Address</title>
              </Helmet>
              <CareplanConfirmed />
            </>
          }
        />

        {/* user routes */}

        <Route
          path="/my-profile"
          element={
            <>
              <UserProfile />
            </>
          }
        />

        <Route
          path="/repair-history"
          exact
          element={
            <>
              <RepairHistory />
            </>
          }
        />

        <Route
          path="/repair-history/:id"
          element={
            <>
              <BookingDetails />
            </>
          }
        />

        <Route
          path="/care-history"
          exact
          element={
            <>
              <CareHistory />
            </>
          }
        />

        <Route
          path="/care-history/:id"
          element={
            <>
              <CareDetailed />
            </>
          }
        />

        <Route
          path="/banner"
          element={
            <>
              <Banner />
            </>
          }
        />

        {/* Protected Login route */}
        <Route
          path="/user/login"
          element={
            <AuthGuard>
              <UserLogin />
            </AuthGuard>
          }
        />

        <Route
          path="/gadget-heros"
          element={
            <>
              <GadgetHeros />
            </>
          }
        />
        <Route
          path="/gr-outpost"
          element={
            <>
              <GrOutpost />
            </>
          }
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
