import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { FaPhoneAlt, FaRegUser } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { HiPencil } from "react-icons/hi";
import InfiniteScroll from "react-infinite-scroll-component";
import { Table } from "antd";
import { GoGift } from "react-icons/go";
import Loader from "../../components/loader/Loader";

const fetchAddressData = async (token, page) => {
  const options = {
    method: "GET",
    url: "https://apiv1.gadgetsreborn.com:7002/auth-service/auth/details",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { page: page, limit: 8 },
  };
  try {
    const response = await axios.request(options);
    return response.data.data.address;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserProfile = () => {
  const [addresses, setAddresses] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState({
    id: "N/A",
    firstName: "N/A",
    lastName: "N/A",
    email: "N/A",
    mobileNo: "N/A",
  });
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});

  const loadMoreAddresses = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("authToken");
      const newAddresses = await fetchAddressData(token, page);
      setAddresses((prev) => [...prev, ...newAddresses]);
      setPage((prev) => prev + 1);
      if (newAddresses.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = sessionStorage.getItem("authToken"); // Get token from local storage
      try {
        const options = {
          method: "GET",
          url: "https://apiv1.gadgetsreborn.com:7002/auth-service/auth/details",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.request(options);
        const data = response.data.data;
        setProfileData({
          id: data?.id || "N/A",
          firstName: data?.firstName || "N/A",
          lastName: data?.lastName,
          email: data?.email || "N/A",
          mobileNo: data?.mobileNo || "N/A",
        });
        setFormData({
          firstName: data?.firstName || "N/A",
          lastName: data?.lastName || "N/A",
          email: data?.email || "N/A",
          mobileNo: data?.mobileNo || "N/A",
        });
        // Optionally, you can fetch addresses here as well
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProfileData();
    loadMoreAddresses();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setFormData({
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      email: profileData.email,
      mobileNo: profileData.mobileNo,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const token = sessionStorage.getItem("authToken");
    const options = {
      method: "PUT",
      url: "https://apiv1.gadgetsreborn.com:7002/auth-service/auth/update-user",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    try {
      const response = await axios.request(options);
      setProfileData((prev) => ({ ...prev, ...formData }));
      setEditMode(false);

      // Store the user's name in sessionStorage
      sessionStorage.setItem("userName", formData.firstName);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading && page === 1) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const dataSource = [
    {
      key: "1",
      serialNo: 1,
      referralCode: "AD216637",
      activeDate: "02 AUG 2024",
      expDate: "02 AUG 2024",
      amount: "AED 23",
    },
  ];

  const columns = [
    {
      title: "S/NO",
      dataIndex: "serialNo",
      key: "serialNo",
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      key: "referralCode",
    },
    {
      title: "Active Date",
      dataIndex: "activeDate",
      key: "activeDate",
    },
    {
      title: "Exp Date",
      dataIndex: "expDate",
      key: "expDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Share",
      key: "share",
      render: () => (
        <button className="p-[8px] bg-yellow-400 rounded-[6px] shadow">
          <GoGift />
        </button>
      ),
    },
  ];

  const columns_coupon = [
    {
      title: "S/NO",
      dataIndex: "serialNo",
      key: "serialNo",
    },
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
      key: "couponCode",
    },
    {
      title: "Active Date",
      dataIndex: "activeDate",
      key: "activeDate",
    },
    {
      title: "Exp Date",
      dataIndex: "expDate",
      key: "expDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Share",
      key: "share",
      render: () => (
        <button className="p-[8px] bg-yellow-400 rounded-[6px] shadow">
          <GoGift />
        </button>
      ),
    },
  ];

  const dataSource_coupon = [
    {
      key: "1",
      serialNo: 1,
      couponCode: "AD216637",
      activeDate: "02 AUG 2024",
      expDate: "02 AUG 2024",
      amount: "AED 23",
    },
  ];

  const isValidMobileNumber = (number) => {
    return number.startsWith("9715") && number.length <= 12;
  };

  return (
    <div className="sm:mx-[50px]">
      <Loader />
      <Navbar />
      <div className="min-h-screen">
        <div className="border rounded-[24px] shadow py-4 px-6 sm:mx-[60px] mx-[24px] mt-[42px]">
          <div className="flex gap-[14px]">
            <div>
              <div className="flex gap-[6px] items-center">
                <FaRegUser className="text-[13px] text-yellow-500" />
                <h3 className="text-sm font-bold text-[#475467]">Name *</h3>
              </div>
              {editMode ? (
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="border p-1 rounded focus:outline-none"
                />
              ) : (
                <p className="text-[14px] text-[#475467] ml-4">
                  {profileData?.firstName}
                </p>
              )}{" "}
              {/* {editMode ? (
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                ) : (
                  profileData?.lastName
                )} */}
              {/* <p className="text-[#475467] text-sm mt-4 md:mt-0">
                YourId: {profileData?.id}
              </p> */}
            </div>
            <div className="ml-auto cursor-pointer">
              {editMode ? (
                <>
                  <div className="space-y-4 md:space-x-3 md:space-y-0 flex flex-col md:flex-row">
                    <button
                      disabled={
                        !formData.firstName ||
                        !isValidMobileNumber(formData.mobileNo) ||
                        !formData.email
                      }
                      onClick={handleSave}
                      className="text-yellow-500 border border-yellow-500 bg-white hover:bg-green-50 py-2 px-4 rounded transition-colors duration-300 w-full md:w-[90px]"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className="text-black border border-black bg-white hover:bg-red-50 py-2 px-4 rounded transition-colors duration-300 w-full md:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <HiPencil size={18} color="#475467" onClick={handleEditClick} />
              )}
            </div>
          </div>
          <div className="mt-4 flex flex-col sm:flex-row sm:gap-[42px] gap-[10px]">
            <div className="flex flex-col items-start justify-center w-full sm:w-1/2">
              <div className="flex gap-[6px]">
                <MdOutlineMail size={18} color="#E4B703" />
                <h3 className="text-sm font-bold text-[#475467]">Email *</h3>
              </div>
              {editMode ? (
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="border p-1 rounded w-full focus:outline-none"
                />
              ) : (
                <p className="text-[12px] text-[#475467] ml-6">
                  {profileData?.email}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start justify-center w-full sm:w-1/2">
              <div className="flex gap-[6px]">
                <FaPhoneAlt size={16} color="#E4B703" />
                <h3 className="text-sm font-bold text-[#475467]">
                  Phone Number *
                </h3>
              </div>
              {editMode ? (
                <input
                  type="number"
                  name="mobileNo"
                  value={formData.mobileNo}
                  placeholder="9715XXXXXXXX"
                  required
                  maxLength={12}
                  onChange={(e) => {
                    if (e.target.value.length <= 12) {
                      setFormData((prev) => ({
                        ...prev,
                        mobileNo: e.target.value,
                      }));
                    }
                  }}
                  className="border p-1 rounded w-full focus:outline-none"
                />
              ) : (
                <p className="text-[12px] text-[#475467] ml-5">
                  {profileData?.mobileNo}
                </p>
              )}
            </div>
          </div>
          <div className="relative">
            <InfiniteScroll
              dataLength={addresses?.length}
              next={loadMoreAddresses}
              hasMore={hasMore}
              loader={<Loader />}
              endMessage={<p>No more addresses</p>}
              className="overflow-y-auto"
              style={{ height: "calc(8 * 80px)" }} // Adjust based on card height
            >
              <div className="grid sm:grid-cols-4 grid-col-1 gap-4 py-4">
                {addresses.map((address, index) => (
                  <div key={index} className="border rounded bg-white p-4">
                    <div className="flex gap-1">
                      <IoLocationOutline size={16} color="#E4B703" />
                      <h3 className="text-sm font-bold text-[#475467]">
                        Address
                      </h3>
                    </div>
                    <p className="text-[12px] text-[#475467]">
                      {address.apartment || "N/A"} + {address.locality || "N/A"}{" "}
                      + {address?.landMark || "N/A"}
                    </p>
                    <p className="text-[12px] text-[#475467]">
                      Name: {address?.name || "N/A"}
                    </p>
                    <p className="text-[12px] text-[#475467]">
                      Email: {address?.email || "N/A"}
                    </p>
                    <p className="text-[12px] text-[#475467]">
                      Phone: {address?.mobileNo || "N/A"}
                    </p>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>

        {/* <div className="mx-[48px] mt-6 hidden md:block">
          <p className="px-4 font-semibold">Referal code</p>
          <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
        <div className="mx-[48px] mt-6 hidden md:block">
          <p className="px-4 font-semibold">Coupon code</p>
          <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
            <Table
              dataSource={dataSource_coupon}
              columns={columns_coupon}
              pagination={false}
            />
          </div>
        </div> */}

        {/* <div className="mx-[20px] mt-6 md:hidden block">
          <p className="px-4 font-semibold">Referral Code</p>
          <div className="shadow rounded-[12px] border p-6 mt-[10px]">
            <div className="flex justify-between items-center border-b-2 pb-2">
              <div className="text-[#475467]">
                <p className="text-[10px]">Referrals Code</p>
                <h3 className="text-sm font-medium">AD216637</h3>
              </div>
              <div>
                <button className="p-[8px] bg-yellow-400 rounded-[6px] shadow">
                  <GoGift />
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center mt-[8px]">
              <div className="flex gap-4">
                <div className="text-[#475467]">
                  <p className="text-[10px]">Active Date</p>
                  <h3 className="text-sm font-medium whitespace-nowrap">
                    02 AUG 2024
                  </h3>
                </div>
                <div>
                  <p className="text-[10px]">Exp Date</p>
                  <h3 className="text-sm font-medium whitespace-nowrap">
                    02 AUG 2024
                  </h3>
                </div>
              </div>
              <div>
                <h3 className="text-[#101828] font-semibold text-[18px]">
                  AED 23
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-[20px] mt-6 md:hidden block">
          <p className="px-4 font-semibold">Coupon Code</p>
          <div className="shadow rounded-[12px] border p-6 mt-[10px]">
            <div className="flex justify-between items-center border-b-2 pb-2">
              <div className="text-[#475467]">
                <p className="text-[10px]">Coupon Code</p>
                <h3 className="text-sm font-medium">AD216637</h3>
              </div>
              <div>
                <button className="p-[8px] bg-yellow-400 rounded-[6px] shadow">
                  <GoGift />
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center mt-[8px]">
              <div className="flex gap-4">
                <div className="text-[#475467]">
                  <p className="text-[10px]">Active Date</p>
                  <h3 className="text-sm font-medium whitespace-nowrap">
                    02 AUG 2024
                  </h3>
                </div>
                <div>
                  <p className="text-[10px]">Exp Date</p>
                  <h3 className="text-sm font-medium whitespace-nowrap">
                    02 AUG 2024
                  </h3>
                </div>
              </div>
              <div>
                <h3 className="text-[#101828] font-semibold text-[18px]">
                  AED 23
                </h3>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default UserProfile;
