import React from 'react';

const steps = [
  { id: 1, label: 'Select Issue', isActive: false},
  { id: 2, label: 'Select Device', isActive: false},
  { id: 3, label: 'Get Estimation', isActive: true},
  { id: 4, label: 'Select Address', isActive: false},
  { id: 5, label: 'Confirm Booking', isActive: false }
];

const ScreenNav = () => {
    const lastActiveStepIndex = steps.findIndex(step => step.isActive);
    return (

        <div className="relative hidden lg:flex flex-col items-start border-b-[1px] h-[300px] self-start ">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center relative mb-8">
            <div className="flex flex-col items-center relative">
              <div
                className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center ${step.isActive ? 'shadow-[0px_0px_10px_1px] shadow-yellow-200': 'shadow-none'} ${
                  step.isActive || index < lastActiveStepIndex  ? 'border-[11px] border-yellow-500 bg-white'  : 'border-[3px] border-gray-200 bg-white'
                }`}
              >
                {step.isActive ? (
                  <div className="h-4 w-4 rounded-full bg-white-200"></div>
                ) :index < lastActiveStepIndex ? (
                  <div className="h-[25px] w-[25px] rounded-full bg-yellow-500 flex items-center justify-center text-white text-sm">✔</div>
                ) : (
                  <div className="h-[12px] w-[12px] rounded-full bg-gray-200"></div>
                )}
              </div>
              {index < steps.length - 1 && (
                <div className={`absolute top-full mt-1 h-8 w-px bg-gray-200 ${index <= lastActiveStepIndex-1 ? 'bg-yellow-500' : 'bg-gray-200'} `}></div>
              )}
            </div>
            <div className={`ml-4 text-lg steps font-semibold ${step.isActive ? 'text-yellow-500' : 'text-[#344054]'}`}>
              {step.label}
            </div>
          </div>
        ))}
      </div>
      );
}

export default ScreenNav