import React from "react";
import MobileNav from "../defects/MobileNav";
import DeviceSelection from "./DeviceSelection";
import ScreenNav from "./ScreenNav";
import Comments from "./Comments";
import Issue from "./Issue";
import Navbar from "../Navbar";
import { useNavigate, useLocation } from "react-router-dom";

const Integration = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { name, description, imageUrl } = location.state || {};
  const Title = name || "Screen";
  const Description = description || "Cracks on screen, display unresponsive or water damage on screen?";

  return (
    <div className=" bg-grSilver overflow-hidden">
      <Navbar />
      <div className="gap-[50px] flex flex-col sm:px-0 px-[24px] items-center sm:mx-0 mx-[24px] pb-[90px] ">
        <div className="flex lg:flex-row flex-col lg:gap-[250px] xl:gap-[370px] gap-[40px] place-content-center lg:ml-0">
          <div className="lg:hidden block self-center mb-[-20px]">
            <MobileNav />
          </div>
          <DeviceSelection
            name={name}
            description={description}
            imageUrl={imageUrl}
          />
          <div className="sm:mt-[60px] flex lg:justify-center justify-start w-[350px] lg:border-l-[2px] border-t-[2px] lg:border-t-[0px] ">
            <div className="flex flex-col gap-[30px] lg:ml-10">
              <div className="hidden lg:block">
                <ScreenNav />
              </div>
              <div className="flex flex-col gap-[10px] border-b-[1px] h-[140px] sm:py-0 py-4">
                <div className="flex flex-row justify-between sm:ml-12 lg:ml-0 ">
                  <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                    Your Issue
                  </p>
                  <button
                    className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                    onClick={() => navigate("/repair/defects")}
                  >
                    Change
                  </button>
                </div>
                <Issue
                  title={Title}
                  description={Description}
                  icon={imageUrl}
                />
              </div>
              <Comments />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
