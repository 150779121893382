import React from "react";
import Navbar from "../Navbar";
import Issue from "../category/Issue";
import Comments from "../defects/Comments";
import SignupPage from "../../pages/SignupPage";
import MobileNav from "./MobileNav";
import ScreenNav from "./ScreenNav";
import YourDevice from "../category/YourDevice";
import { useNavigate, useLocation } from "react-router-dom";

const Integration = () => {
  const categoryName = sessionStorage.getItem("categoryName");
  let Type = categoryName;

  const navigate = useNavigate();
  const location = useLocation();

  const { brandName, modelName, name, description, imageUrl } =
    location.state || {};
  // console.log("brand name:", brandName, "model name:", modelName);
  const Title = name || "Screen";
  const Description =
    description ||
    "Cracks on screen, display unresponsive or water damage on screen?";
  // console.log("Title:", Title, "Description:", Description);
  let Title1 = brandName;

  return (
    <div className="bg-grSilver">
      <Navbar />
      <div className="flex flex-col pb-[90px] px-4 sm:px-6 lg:px-8 items-center justify-center sm:mt-[60px] mt-8">
        <div className="flex flex-col lg:flex-row gap-8 w-full">
          <div className="lg:hidden block self-center mb-[-20px]">
            <MobileNav />
          </div>
          <div className="lg:flex-grow flex-shrink-0">
            <SignupPage />
          </div>
          <div className="flex flex-col gap-8 lg:gap-12 lg:border-l-[2px] border-t-[2px] lg:border-t-0 lg:pl-6">
            <div className="hidden lg:block">
              <ScreenNav />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont">
                  Your Device
                </p>
                <button
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  Change
                </button>
              </div>
              <YourDevice
                phone={Title1}
                type={Type}
                Brand={brandName}
                Model={modelName}
              />
              <div className="flex justify-between items-center mt-4">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont">
                  Your Issue
                </p>
                <button
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                  onClick={() => navigate("/defects")}
                >
                  Change
                </button>
              </div>
              <Issue title={Title} description={Description} icon={imageUrl} />
            </div>
            <Comments />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
