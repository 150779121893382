import React from 'react'
import HeaderRepair from '../../../components/repairGadgets/HeaderRepair'
import FeaturesRepair from '../../../components/repairGadgets/FeaturesRepair'
import TestimonialsRepair from '../../../components/repairGadgets/TestimonialsRepair'
import Newsletter from '../../../components/repairGadgets/Newsletter'
import Footer from '../../../components/Footer'


const LandingPage = () => {
  return (
    <div className='flex flex-col sm:mx-[50px]'>
      <HeaderRepair/>
      <FeaturesRepair/>
      <TestimonialsRepair/>
      {/* <Newsletter/> */}
      <div>
      <Footer/>
      </div>
    </div>
  )
}

export default LandingPage