import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const SessionStorageClearer = () => {
  const location = useLocation();
  const previousPathname = useRef(null);

  useEffect(() => {
    const pathsToClear = ['/', '/repair', '/care'];
    if (
      pathsToClear.includes(location.pathname) &&
      previousPathname.current === '/booking-confirmed'
    ) {
      sessionStorage.clear();
      // console.log('Session storage cleared');
    }
    previousPathname.current = location.pathname;
  }, [location]);

  return null; // This component doesn't render anything
};

export default SessionStorageClearer;
