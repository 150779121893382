import React from 'react';
import icon from './img/Featured icon(4).png';

const YourDevice = ({ phone, type, Brand, Model }) => {
  return (
    <div className='bg-[#FFFFFF] rounded-[12px] p-4 flex items-center border border-[#EAECF0] w-full max-w-[350px] lg:max-w-none'>
      <img src={icon} className='h-[40px] w-[40px]' alt='Device Icon' />
      <div className='flex flex-col ml-4'>
        <div className='font-bold text-[20px] leading-[24px] text-[#344054]'>{phone}</div>
        <div className='text-[#475467] text-[12px] leading-[18px] mt-1'>
          <div className='flex'>
            <p className='text-[#344054] mr-1 text-[16px] font-bold'>Type:</p>
            <p className='text-[16px]'>{type}</p>
          </div>
          <div className='flex'>
            <p className='text-[#344054] mr-1 text-[16px] font-bold'>Brand:</p>
            <p className='text-[16px]'>{Brand}</p>
          </div>
          <div className='flex'>
            <p className='text-[#344054] mr-1 text-[16px] font-bold'>Model:</p>
            <p className='text-[16px]'>{Model}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourDevice;
