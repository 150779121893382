import React, { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import LocationIcon from "./assets/Group 8796.png";
import { useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import SearchIcon from "./assets/Search_alt.png";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
const libraries = ["places", "marker"];

const Address = () => {
  const authToken = sessionStorage.getItem("authToken");
  const location = useLocation();
  useEffect(() => {
    if (sessionStorage.getItem("userEmail")) {
      setShowMobileInput(true);
    }
    if (sessionStorage.getItem("userPhoneNumber")) {
      setShowEmailInput(true);
    }
  }, []);
  useEffect(() => {
    // console.log("location.state:", location.state);
  }, [location.state]);
  const { estimatedPrice, name } = location.state ?? {};

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const initialCenter = {
    lat: 25.276987,
    lng: 55.296249,
  };

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(initialCenter);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [additionalAddress, setAdditionalAddress] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [mainAddressCountry, setMainAddressCountry] = useState("");
  const [additionalAddressCountry, setAdditionalAddressCountry] = useState("");
  const [geoFenceError, setGeoFenceError] = useState(""); // Add this state
  const [showMobileInput, setShowMobileInput] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false); // Add this state
  const [couponStatus, setCouponStatus] = useState("");
  const [afterPrice, setAfterPrice] = useState(null);
  const searchBoxRef = useRef(null);
  const navigate = useNavigate();

  const handleLoad = async (mapInstance) => {
    // console.log("Map loaded:", mapInstance);
    setMap(mapInstance);

    // Center the map on initial load
    mapInstance.setCenter(initialCenter);
    mapInstance.setZoom(5); // Set initial zoom level

    const input = document.getElementById("address-search");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "ae" }, // Restrict to UAE
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) return;

      const newLocation = place.geometry.location;
      setCenter(newLocation);
      updateMarkerPosition(newLocation);
      setAddress(place.formatted_address);

      // Adjust zoom level and center the map on search
      mapInstance.setZoom(15);
      mapInstance.setCenter(newLocation);
    });

    // Add the initial marker and set map center if mapInstance is not null
    if (mapInstance) {
      try {
        await addMarker(mapInstance, initialCenter, 10); // Pass the zoom level here
        mapInstance.setCenter(initialCenter); // Ensure map is centered on initial load
      } catch (error) {
        console.error("Error adding marker:", error);
        toast.error("Error adding marker");
      }
    }
  };

  useEffect(() => {
    if (address.length > 0) setIsAddressSelected(true);
  }, [address]);

  const addMarker = async (mapInstance, position, zoomLevel = 10) => {
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );
    const newMarker = new AdvancedMarkerElement({
      map: mapInstance,
      position: position,
      title: "Selected Location",
      draggable: true,
    });

    newMarker.addListener("dragend", async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const newPosition = { lat, lng };

      setCenter(newPosition);
      updateMarkerPosition(newPosition);

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          setAddress(results[0].formatted_address);
          getCountryFromAddress(
            results[0].formatted_address,
            setMainAddressCountry
          );
        }
      });

      mapInstance.setZoom(15);
      mapInstance.setCenter(newPosition);
    });

    setMarker(newMarker);
    mapInstance.setZoom(zoomLevel);
  };

  const updateMarkerPosition = (position) => {
    if (marker) {
      marker.position = position;
    } else if (map) {
      addMarker(map, position);
    }
  };

  useEffect(() => {
    if (map) {
      const handleClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newCenter = { lat, lng };

        setCenter(newCenter);
        updateMarkerPosition(newCenter);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: newCenter }, (results, status) => {
          if (status === "OK" && results[0]) {
            setAddress(results[0].formatted_address);
            getCountryFromAddress(
              results[0].formatted_address,
              setMainAddressCountry
            );
          }
        });

        map.setZoom(15);
        map.setCenter(newCenter);
      };

      map.addListener("click", handleClick);

      return () => {
        window.google.maps.event.clearListeners(map, "click");
      };
    }
  }, [map, marker]);

  const getCountryFromAddress = (address, setCountry) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );
        if (countryComponent) {
          setCountry(countryComponent.long_name);
        }
      }
    });
  };

  const handleZoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  };

  const handleZoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(currentLocation);
        updateMarkerPosition(currentLocation);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: currentLocation }, (results, status) => {
          if (status === "OK" && results[0]) {
            setAddress(results[0].formatted_address);
            getCountryFromAddress(
              results[0].formatted_address,
              setMainAddressCountry
            );
          }
        });

        map.setZoom(15);
        map.setCenter(currentLocation);
      });
    }
  };

  const isValidMobileNumber = (number) => {
    return number.startsWith("9715") && number.length === 12; // Ensure the number is exactly 12 digits
  };

  const handleCaptchaVerify = (value) => {
    if (value) {
      setCaptchaVerified(true);
      setCaptchaToken(value);
    }
  };

  const handleAdditionalAddressChange = (e) => {
    const value = e.target.value;
    setAdditionalAddress(value);
    if (value) {
      getCountryFromAddress(value, setAdditionalAddressCountry);
    }
  };
  const handleCouponCodeChange = (e) => {
    const value = e.target.value;
    setCouponCode(value);
  };

  const handleApplyCoupon = async (e) => {
    e.preventDefault();

    try {
      const storedPrice = sessionStorage.getItem("selectedPrice");
      const authToken = sessionStorage.getItem("authToken");
      if (couponCode && storedPrice) {
        const options = {
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/coupans/validate`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: { couponCode, price: parseFloat(storedPrice) },
        };
        const { data } = await axios.request(options);
        sessionStorage.setItem("afterPrice", data.afterPrice);
        setAfterPrice(data.afterPrice);

        // Compare the afterPrice with the original storedPrice
        if (data.afterPrice === parseFloat(storedPrice)) {
          setCouponStatus("Invalid coupon code");
        } else {
          setCouponStatus("Coupon code applied");
        }
      } else {
        setCouponStatus("Coupon code not provided or price missing");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        setCouponStatus("Invalid coupon code");
      } else {
        console.error("Error validating coupon code:", error);
      }
    }
  };

  // Update handleContinue to include the country check and warning message

  const fetchServiceUuid = async (estimatedPrice) => {
    const token = sessionStorage.getItem("authToken");
    const defectName = sessionStorage.getItem("defectName");
    const brandSkuCode = sessionStorage.getItem("brandSkuCode");
    const categoryName = sessionStorage.getItem("categoryName");
    const defectSkuCode = sessionStorage.getItem("defectSkuCode");
    const modelSkuCode = sessionStorage.getItem("modelSkuCode");
    const categorySkuCode = sessionStorage.getItem("categorySkuCode");
    const modelName = sessionStorage.getItem("modelName");
    const brandName = sessionStorage.getItem("brandName");

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/service-request/add`,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "*/*",
      },
      data: {
        categorySku: categorySkuCode,
        categoryName: categoryName,
        brandSku: brandSkuCode,
        brandName: brandName,
        modelSku: modelSkuCode,
        modelName: modelName,
        totalAmount: estimatedPrice,
        defectName: defectName,
        defectSku: defectSkuCode,
        couponCode: couponCode || null,
      },
    };

    try {
      const { data } = await axios.request(options);
      console.log("ServiceId", data);
      sessionStorage.setItem("serviceUuid", data.serviceUuid);
      console.log("ServiceId", data.serviceUuid);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    const price = sessionStorage.getItem("selectedPrice");
    await fetchServiceUuid(price);
    const serviceRequestId = sessionStorage.getItem("serviceUuid");
    if (mainAddressCountry !== additionalAddressCountry) {
      setGeoFenceError(
        "The main address and additional address must be in the same country."
      );
      return;
    } else {
      setGeoFenceError(""); // Clear the error if countries match
    }
    try {
      const name = `${firstName} ${lastName}`;
      const emailValue = sessionStorage.getItem("userEmail") || email; // Assuming email is a state variable
      const mobileNoValue =
        sessionStorage.getItem("userPhoneNumber") || mobileNumber; // Assuming mobileNumber is a state variable
      const locality = address; // Assuming address is a state variable
      const landMark = additionalAddress; // Assuming additionalAddress is a state variable
      const zipCode = ""; // Optional zip code
      const latitude = []; // Optional latitude values
      const longitude = []; // Optional longitude values
      const apartmentValue = apartment; // Assuming apartment is a state variable

      // First API call
      const addressOptions = {
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/service-request/assign-address`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "application/json",
        },
        data: {
          name,
          email: emailValue,
          mobileNo: mobileNoValue,
          serviceRequestId,
          locality,
          landMark,
          zipCode,
          latitude,
          longitude,
          apartment: apartmentValue,
        },
      };

      const addressResponse = await axios.request(addressOptions);
      // console.log(addressResponse.data);

      const paymentOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}:9009/payment-service/api/payment/make`,
        params: {
          bookingId: serviceRequestId,
          ...(couponCode &&
            couponStatus === "Coupon code applied" && { couponCode }), // Include coupon code if valid
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const paymentResponse = await axios.request(paymentOptions);

        // Redirect user to payment URL if it's a valid URL
        if (paymentResponse && paymentResponse.data) {
          window.location.href = paymentResponse.data;
        } else {
          console.error("Invalid payment URL received.");
        }
      } catch (error) {
        console.error("Error processing payment:", error);
      }
    } catch (error) {
      console.error("Error assigning address:", error);
    }
  };

  const handleGoBack = () => {
    navigate("/repair/booking-fee", { state: { ...location.state } });
  };
  return (
    <div className="container mx-auto">
      <ToastContainer />
      <button
        onClick={handleGoBack}
        className="hidden w-28 h-9 px-4 mb-3 py-2 sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
      >
        <span className="">
          <MdKeyboardArrowLeft />
        </span>
        <span className="font-bold font-plus-jakarta-sans text-[14px] leading-[20px]">
          Go Back
        </span>
      </button>
      <h1 className="font-bold sm:mt-6 font-plus-jakarta-sans text-4xl mb-4  xl:max-w-[400px]">
        Select your address
      </h1>
      <p className="text-lg whitespace-nowrap font-plus-jakarta-sans text-[#475467] mb-8 xl:max-w-[330px]">
        Where should we come to collect the device?
      </p>
      <div className="flex flex-col lg:flex-row lg:space-x-8 space-y-8 lg:space-y-0">
        <div className="relative w-full lg:w-[400px] h-96 lg:h-[690px]">
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
            id="script-loader"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={handleLoad}
              options={{
                fullscreenControl: false,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                mapId: process.env.REACT_APP_GOOGLE_MAPID_KEY,
              }}
            />
          </LoadScript>
          <div className="absolute top-6 left-8 right-8 flex items-center">
            <img
              src={SearchIcon}
              alt="Search"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
            />
            <input
              id="address-search"
              type="text"
              placeholder="Search address"
              className="w-full pl-10 p-2 focus:outline-none rounded-md border border-[#D0D5DD] shadow-md placeholder-[#D0D5DD]"
              ref={searchBoxRef}
            />
          </div>
          <div className="absolute bottom-4 right-4 flex items-center space-x-2">
            <button
              onClick={handleZoomIn}
              className="bg-white p-2 w-8 h-8 rounded shadow-md"
            >
              +
            </button>
            <button
              onClick={handleZoomOut}
              className="bg-white p-2 w-8 h-8 rounded shadow-md"
            >
              -
            </button>
            <button
              onClick={handleCurrentLocation}
              className="bg-white p-2 w-36 h-8 rounded shadow-md flex items-center whitespace-pre font-plus-jakarta-sans text-sm"
            >
              <img
                src={LocationIcon}
                alt="Current Location"
                className="w-5 h-5 mr-2"
              />
              Current location
            </button>
          </div>
        </div>
        {isAddressSelected && (
          <div className=" bg-white p-8 rounded-lg shadow-md border border-[#D0D5DD] sm:w-[425px]">
            <form className="flex flex-col gap-4">
              <div className="mb-4">
                <label
                  htmlFor="apartment"
                  className="block text-sm font-medium text-gray-700"
                >
                  Apartment / Villa*
                </label>
                <input
                  type="text"
                  id="apartment"
                  className="mt-1 block w-full focus:outline-none rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                  placeholder="eg-SH-17"
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your Address*
                </label>
                <input
                  type="text"
                  id="address"
                  className="mt-1 block w-full focus:outline-none rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                  placeholder="Al Shamkhah – Abu Dhabi"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="additional-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Additional Address
                </label>
                <input
                  type="text"
                  id="additional-address"
                  className="mt-1 block w-full rounded-md focus:outline-none h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                  placeholder="Enter your address here"
                  value={additionalAddress}
                  onChange={handleAdditionalAddressChange}
                />
                {geoFenceError && (
                  <p className="text-red-600 mt-2">{geoFenceError}</p>
                )}{" "}
                {/* Warning message */}
              </div>
              <h2 className="text-base font-bold text-[#344054]">
                Additional Information
              </h2>
              <div className="flex space-x-4 mb-4">
                <div className="flex-1">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name*
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                    placeholder="Varun"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name*
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                    placeholder="Sunara"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                {" "}
                {showEmailInput ? (
                  <>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email*
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="mt-1 block w-full rounded-md focus:outline-none h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                      placeholder="eg- user@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </>
                ) : showMobileInput ? (
                  <>
                    <label
                      htmlFor="mobile-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mobile number*
                    </label>
                    <input
                      type="text"
                      id="mobile-number"
                      className="mt-1 block w-full rounded-md focus:outline-none h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                      placeholder="9715XXXXXXXX"
                      value={mobileNumber}
                      onChange={(e) => {
                        // Only update the state if the new value has at most 12 digits and is numeric
                        const newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        if (newValue.length <= 12) {
                          setMobileNumber(newValue);
                        }
                      }}
                      required
                      maxLength={12} // Limit the input length to 12 characters
                    />
                  </>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  htmlFor="coupon-code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Coupon Code (Optional)
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={couponCode}
                    onChange={handleCouponCodeChange}
                    placeholder="Enter coupon code"
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <button
                    onClick={handleApplyCoupon}
                    className="bg-yellow-500 text-white p-2 w-full rounded-md"
                  >
                    Apply
                  </button>
                </div>
                {couponStatus && (
                  <div className="mt-2 flex items-center space-x-2">
                    <p
                      className={`${
                        couponStatus === "Coupon code applied"
                          ? "text-green-500 text-sm"
                          : "text-red-500"
                      }`}
                    >
                      {couponStatus === "Coupon code applied"
                        ? "Your coupon code has been successfully applied and will be used during the final payment process."
                        : couponStatus}
                    </p>
                  </div>
                )}
              </div>
              {/* <p className="text-center text-sm text-red-700">Terms & Conditions</p> */}
              {/* <ReCAPTCHA
                sitekey=""
                onChange={handleCaptchaVerify}
              /> */}
              <button
                disabled={
                  !apartment ||
                  !address ||
                  !firstName ||
                  !lastName ||
                  !(isValidMobileNumber(mobileNumber) || email) ||
                  mainAddressCountry !== additionalAddressCountry
                }
                type="submit"
                className="w-full px-6 py-3 bg-yellow-500 hover:bg-yellow-500 hover:bg-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed text-white font-semibold rounded-md shadow-sm"
                onClick={handleContinue}
              >
                Confirm Booking
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Address;
