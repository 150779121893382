import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import ScreenNav from "../../components/SelectDevice/ScreenNav";
import Comment from "../../components/SelectDevice/Comments";
import { IoIosArrowDown } from "react-icons/io";
import MobileNav from "../../components/EstimatedPrice/MobileNav";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";

function SelectDevice() {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [storages, setStorages] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelName, setSelectedModelName] = useState("");
  const [selectedStorage, setSelectedStorage] = useState("");
  const [deviceValue, setDeviceValue] = useState("");
  const [deviceValueVisible, setDeviceValueVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Clear device value and other session storage when the page loads
    setDeviceValue("");
    sessionStorage.removeItem("caredeviceValue");
    sessionStorage.removeItem("carebrandSkuCode");
    sessionStorage.removeItem("caremodelSkuCode");
    sessionStorage.removeItem("careStorageSkuCode");
    setDeviceValueVisible(false);

    // Fetch brands and other initial data here...
  }, []);

  useEffect(() => {
    const fetchBrands = async () => {
      const carecategorySkuCode = sessionStorage.getItem("carecategorySkuCode");
      if (!carecategorySkuCode) return;

      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/brands/find-by-category-for-careplan`,
        params: { categorySku: carecategorySkuCode },
        headers: { accept: "*/*" },
      };

      try {
        const { data } = await axios.request(options);
        setBrands(data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch brands.");
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchDeviceValue = async () => {
      const categorySkuCode = sessionStorage.getItem("carecategorySkuCode");
      const brandSkuCode = sessionStorage.getItem("carebrandSkuCode");
      const modelSkuCode = sessionStorage.getItem("caremodelSkuCode");
      const storageSkuCode = sessionStorage.getItem("careStorageSkuCode");

      if (
        !categorySkuCode ||
        !brandSkuCode ||
        !modelSkuCode ||
        !storageSkuCode
      ) {
        return;
      }

      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/care/estimate-price`,
        params: {
          categorySkucode: categorySkuCode,
          brandSkuCode: brandSkuCode,
          modelSkuCode: modelSkuCode,
          storageSku: storageSkuCode,
        },
        headers: { accept: "*/*" },
      };

      try {
        const { data } = await axios.request(options);
        if (data && data.data) {
          const roundedValue = Math.round(data.data);
          setDeviceValue(roundedValue);
          sessionStorage.setItem("caredeviceValue", roundedValue);
          setDeviceValueVisible(true);
        } else {
          sessionStorage.removeItem("caredeviceValue");
          setDeviceValue("");
          setDeviceValueVisible(false);
        }
      } catch (error) {
        console.error(error);
        sessionStorage.removeItem("caredeviceValue");
        setDeviceValue("");
        setDeviceValueVisible(false);
      }
    };

    fetchDeviceValue();
  }, [selectedBrand, selectedModel, selectedStorage]);

  const handleBrandChange = async (e) => {
    const brandSkuCode = e.target.value;
    const brandName = e.target.options[e.target.selectedIndex].text;
    sessionStorage.setItem("carebrandSkuCode", brandSkuCode);
    sessionStorage.setItem("carebrandName", brandName);
    setSelectedBrand(brandSkuCode);
    setSelectedBrandName(brandName);

    setSelectedModel("");
    setSelectedStorage("");
    setDeviceValue("");
    setDeviceValueVisible(false);
    sessionStorage.removeItem("caremodelSkuCode");
    sessionStorage.removeItem("careStorageSkuCode");
    sessionStorage.removeItem("caredeviceValue");

    const carecategorySkuCode = sessionStorage.getItem("carecategorySkuCode");
    if (!carecategorySkuCode || !brandSkuCode) return;

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/model/find-by-category-and-brand-for-careplan`,
      params: { categorySku: carecategorySkuCode, brandSku: brandSkuCode },
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      setModels(data);
      setStorages([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModelChange = async (e) => {
    const modelSkuCode = e.target.value;
    const modelName = e.target.options[e.target.selectedIndex].text;
    sessionStorage.setItem("caremodelSkuCode", modelSkuCode);
    sessionStorage.setItem("caremodelName", modelName);
    setSelectedModel(modelSkuCode);
    setSelectedModelName(modelName);

    setSelectedStorage("");
    setDeviceValue("");
    setDeviceValueVisible(false);
    sessionStorage.removeItem("careStorageSkuCode");
    sessionStorage.removeItem("caredeviceValue");

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/storages/find-by-model-skus`,
      params: { modelSku: modelSkuCode },
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      setStorages(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch storages.");
    }
  };

  const handleStorageChange = (e) => {
    const storageSkuCode = e.target.value;
    const storageName = e.target.options[e.target.selectedIndex].text;
    setSelectedStorage(storageSkuCode);
    sessionStorage.setItem("careStorageName", storageName);
    sessionStorage.setItem("careStorageSkuCode", storageSkuCode);
    setDeviceValueVisible(false);
    sessionStorage.removeItem("caredeviceValue");
    setDeviceValue("");
  };

  const handleContinue = () => {
    const authToken = sessionStorage.getItem("authToken");
    const data = {
      brand: selectedBrandName,
      model: selectedModelName,
      storage: selectedStorage,
      value: deviceValue,
    };

    if (authToken) {
      navigate("/care/estimated-price", { state: data });
    } else {
      navigate("/care/sign-up", { state: data });
    }
  };

  const handleGoBack = () => {
    navigate("/care");
  };

  return (
    <div>
      <div className="bg-[#F9FAFB] sm:mx-[60px] pt-[16px] px-[30px] pb-[90px] min-h-[680px] rounded-b-[40px]">
        <div>
          <Navbar />
        </div>
        <div className="lg:hidden flex justify-center items-center my-8">
          <MobileNav />
        </div>
        <button
          onClick={handleGoBack}
          className="hidden ml-[48px] my-[60px] px-4 mb-4 py-[6px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
        >
          <span className="">
            <MdKeyboardArrowLeft />
          </span>
          <span className="font-bold whitespace-nowrap font-plus-jakarta-sans text-[14px] leading-[20px]">
            Go Back
          </span>
        </button>
        <div className="grid sm:grid-cols-6 grid-cols-1 justify-center sm:mt-[60px] xl:mt-[40px]">
          <div className="col-span-4 sm:ml-[48px] ">
            <div className="text-left">
              <h1 className="sm:text-4xl text-3xl font-plus-jakarta-sans sm:text-[36px] text-[#101828] sm:text-center text-left lg:text-left font-bold leading-[44px]  max-w-[300px] lg:max-w-[500px]">
                Select your device
              </h1>
              <p className="text-gray-500 font-plus-jakarta-sans mt-[8px]">
                Select your device that needs to be warranted.
              </p>
            </div>
            <div className="sm:mt-[40px] mt-[24px] sm:mr-[32px] flex flex-col gap-4">
              <div className="relative w-full">
                <select
                  className=" block overflow-hidden appearance-none text-[16px] w-full bg-white border border-gray-300 text-[#667085] py-3 px-4 pr-8 rounded-md leading-tight focus:outline-none"
                  onChange={handleBrandChange}
                  value={selectedBrand}
                >
                  <option value="" disabled>
                    Brand
                  </option>
                  {brands.map((brand) => (
                    <option key={brand.skuCode} value={brand.skuCode}>
                      {brand.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <IoIosArrowDown className="text-[#667085] h-5 w-5" />
                </div>
              </div>
              <div className="relative">
                <select
                  className="block appearance-none text-[16px] w-full bg-white border border-gray-300 text-[#667085] py-3 px-4 pr-8 rounded-md leading-tight focus:outline-none"
                  onChange={handleModelChange}
                  value={selectedModel}
                >
                  <option value="" disabled>
                    Model
                  </option>
                  {models.map((model) => (
                    <option key={model.skuCode} value={model.skuCode}>
                      {model.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <IoIosArrowDown className="text-[#667085] h-5 w-5" />
                </div>
              </div>
              <div className="relative">
                <select
                  className="block appearance-none text-[16px] w-full bg-white border border-gray-300 text-[#667085] py-3 px-4 pr-8 rounded-md leading-tight focus:outline-none"
                  onChange={handleStorageChange}
                  value={selectedStorage}
                >
                  <option value="" disabled>
                    Select Storage
                  </option>
                  {storages.map((storage) => (
                    <option key={storage.skuCode} value={storage.skuCode}>
                      {storage.storage}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <IoIosArrowDown className="text-[#667085] h-5 w-5" />
                </div>
              </div>
              <div>
                {deviceValueVisible && (
                  <h1 className="font-bold">
                    Estimated Device value:{" "}
                    <span className="text-[#FBC903] font-bold">
                      {deviceValue ? `AED ${deviceValue} ` : "Not Available"}
                    </span>
                  </h1>
                )}
              </div>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className={`bg-yellow-500 text-black font-bold py-[10px] px-[18px] rounded-md ${
                  !selectedBrand ||
                  !selectedModel ||
                  !selectedStorage ||
                  !deviceValue
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={handleContinue}
                disabled={
                  !selectedBrand ||
                  !selectedModel ||
                  !selectedStorage ||
                  !deviceValue
                }
              >
                Continue
              </button>
            </div>
          </div>
          <div className="col-span-2 sm:border-l sm:px-[42px]">
            <div className="lg:block hidden self-center mb-[-20px]">
              <ScreenNav />
            </div>
            <Comment />
          </div>
        </div>
      </div>
      <div className="sm:mx-[50px]">
        <Footer />
      </div>
    </div>
  );
}

export default SelectDevice;
