import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import axios from "axios";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import OtpVerificationModal from "./OtpVerificationModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserLogin = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const handleSubmit = async () => {
    const regex =
      /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|9715\d{8})$/;

    if (!emailOrPhone) {
      setError("Please enter your email or phone number.");
      return;
    }

    if (!regex.test(emailOrPhone)) {
      toast.error("Invalid email or phone number");
      return;
    }

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/send-otp`,
      params: { email: emailOrPhone },
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);

      if (data.statusCode === 400) {
        setErrorMessage("Failed to send OTP. Try with email"); // Set the error message
        return; // Stop further execution
      }

      setErrorMessage("");
      setEmail(emailOrPhone);
      setMobileNumber(emailOrPhone);
      setIsModalOpen(true); // Open the OTP modal on successful API call
    } catch (error) {
      console.error("Failed to send OTP:", error);
      if (error.response && error.response.status === 400) {
        toast.error(
          "Invalid Mobile or Email. Please check your input and try again."
        );
      } else {
        setError("Failed to send OTP. Please try again.");
      }
    }

    sessionStorage.setItem("email", emailOrPhone);
  };

  const handleVerifyOtp = async (otp) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/verify-otp`,
      headers: { "content-type": "application/json", accept: "*/*" },
      data: { otp: otp, email: email },
    };

    try {
      const { data } = await axios.request(options);
      if (data.statusCode === 400) {
        toast.error(data?.msg || "Invalid OTP. Please try again.");
        return;
      }
      const authToken = data?.data?.acessToken;

      sessionStorage.setItem("authToken", authToken);
      navigate("/");
      return { success: true };
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      return { success: false };
    }
  };

  const handleResendOtp = async () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/send-otp`,
      params: { email: emailOrPhone },
      headers: { accept: "*/*" },
    };

    try {
      await axios.request(options);
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (emailOrPhone) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^9715\d{8}$/;

      if (emailPattern.test(emailOrPhone)) {
        sessionStorage.setItem("userEmail", emailOrPhone);
      } else if (phonePattern.test(emailOrPhone)) {
        sessionStorage.setItem("userMobileNumber", emailOrPhone);
      }
    }
  }, [emailOrPhone]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="sm:px-[40px]">
      <ToastContainer />
      <Navbar />
      <div className="flex justify-center items-center py-20 sm:px-0 px-[24px]">
        <div className="sm:max-w-md w-full px-6 py-[34px] bg-white border border-gray-300 rounded-lg">
          <button
            onClick={handleGoBack}
            className="hidden w-28 h-9 px-4 mb-3 py-2 sm:flex items-center  gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <span className="">
              <MdKeyboardArrowLeft />
            </span>
            <span className="font-bold font-plus-jakarta-sans text-[14px] leading-[20px]">
              Go Back
            </span>
          </button>
          <h1 className="text-3xl font-bold text-start mb-6">Login</h1>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <label className="text-[#344054] text-[14px] font-[500]">
                Email
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                value={emailOrPhone}
                onChange={(e) => setEmailOrPhone(e.target.value)}
                className="w-full h-11 p-2 rounded-md border border-gray-300 placeholder-[#667085] text-[16px] leading-[24px] focus:outline-none"
              />
              {errorMessage && (
                <span className="text-red-500 text-sm mt-1">
                  {errorMessage}
                </span>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className="w-full h-11 flex items-center justify-center bg-yellow-400 text-black font-bold rounded-md mt-5"
            >
              Login To Continue
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-30">
          <OtpVerificationModal
            onClose={handleCloseModal}
            email={email}
            mobileNumber={mobileNumber}
            onVerify={handleVerifyOtp}
            onResend={handleResendOtp}
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default UserLogin;
