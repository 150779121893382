import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Category = ({ icon, name, skuCode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    sessionStorage.setItem('categorySkuCode', skuCode);
    sessionStorage.setItem('categoryName', name);
    navigate('/repair/defects', { state: { categorySkuCode: skuCode } });
  };

  const handleCareClick = () => {
    sessionStorage.setItem('carecategorySkuCode', skuCode);
    sessionStorage.setItem('careCategoryName', name);
    navigate('/care/select-device', { state: { carecategorySkuCode: skuCode } });
  };

  const handleCategoryClick = () => {
    if (location.pathname === '/repair') {
      handleClick();
    } else if (location.pathname === '/care') {
      handleCareClick();
    }
  };

  return (
    <div className='flex flex-col text-center mt-8 lg:mt-0 m-2'>
      <div
        className='flex rounded-[12.17px] lg:h-[120px] lg:w-[232px] w-[160px] h-[80px] bg-[#ffffff] justify-center cursor-pointer'
        onClick={handleCategoryClick}
      >
        <img src={icon} className={`self-end img-${skuCode}`} alt={`${name} icon`} />
      </div>
      <div
        className='flex text-center w-full justify-center cursor-pointer'
        onClick={handleCategoryClick}
      >
        <p className='self-center font-plus-jakarta-sans'>{name}</p>
      </div>
    </div>
  );
};

export default Category;
