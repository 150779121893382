import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";

const DefectCard = ({ title, description, icon, skuCode, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.setItem('defectSkuCode', skuCode);
    sessionStorage.setItem('defectName', title);
    navigate('/repair/category', { state: { categorySkuCode: sessionStorage.getItem('categorySkuCode'), defectSkuCode: skuCode, name: title, description, imageUrl: icon } });
  };

  return (
    <div
      className="flex flex-col py-6 px-5 max-w-md border-2 border-gray-200 bg-white rounded-lg shadow-sm cursor-pointer transition-shadow duration-300"
      onClick={handleClick}
    >
      <div className="flex items-center">
        <img src={icon} className="h-10 w-10" alt={title} />
        <div className="flex flex-col items-start gap-2 w-full mb-4 ml-4">
          <h2 className="text-lg xl:text-xl font-semibold text-gray-800">
            {title}
          </h2>
          <p className="text-gray-600 text-sm xl:text-base">{description}</p>
        </div>
        <IoIosArrowForward className="text-black font-bold text-xl" />
      </div>
    </div>
  );
  
};

export default DefectCard;
