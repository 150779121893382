import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const CustomPrevArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 left-0 transform -translate-y-1/2  text-[#1E1E1E] p-[7px] rounded-full cursor-pointer z-10"
    onClick={onClick}
  >
    <IoIosArrowBack size={28} />
  </div>
);

export const CustomNextArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 right-0 transform -translate-y-1/2 text-[#1E1E1E] p-[7px] rounded-full cursor-pointer z-10"
    onClick={onClick}
  >
    <IoIosArrowForward size={28} />
  </div>
);
