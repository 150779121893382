import React from "react";
import Navbar from "../Navbar";
import Issue from "../category/Issue";
import Comments from "../defects/Comments";
import MobileNav from "./MobileNav";
import ScreenNav from "./ScreenNav";
import BookingFeePage from "../../pages/BookingFeePage";
import YourDevice from "../category/YourDevice";
import { useNavigate, useLocation } from "react-router-dom";

const Integration = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { brandName, modelName, name, description, imageUrl } =
    location.state || {};

  const categoryName = sessionStorage.getItem("categoryName");
  const Title1 = brandName || "";
  const Type = categoryName || "";
  const Brand = brandName || "";
  const Model = modelName || "";
  const Title2 = name || "";
  const Description2 = description || "No description provided";

  const handleChange = () => {
    navigate("/repair/defects", { state: { ...location.state } });
  };

  const handlePhoneChange = () => {
    navigate("/repair/category", { state: { ...location.state } });
  };

  return (
    <div className="bg-grSilver">
      <Navbar />
      <div className="flex flex-col pb-[90px] px-4 sm:px-6 lg:px-8 items-center justify-center sm:mt-[60px] mt-8">
        <div className="flex flex-col lg:flex-row gap-8  w-full">
          <div className="lg:hidden block self-center mb-[-20px]">
            <MobileNav />
          </div>
          <div className="lg:flex-grow flex-shrink-0">
            <BookingFeePage />
          </div>
          <div className="flex flex-col gap-8 lg:gap-12 lg:border-l-[2px] border-t-[2px] lg:border-t-0 lg:pl-6">
            <div className="hidden lg:block">
              <ScreenNav />
            </div>
            <div className="flex flex-col gap-4 sm:mt-0 mt-4">
              <div className="flex justify-between items-center">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont">
                  Your Device
                </p>
                <button
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                  onClick={handlePhoneChange}
                >
                  Change
                </button>
              </div>
              <YourDevice
                phone={Title1}
                type={Type}
                Brand={Brand}
                Model={Model}
              />
              <div className="flex justify-between items-center mt-4">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont">
                  Your Issue
                </p>
                <button
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                  onClick={() => navigate("/repair/defects")}
                >
                  Change
                </button>
              </div>
              <Issue
                title={Title2}
                description={Description2}
                icon={imageUrl}
              />
            </div>
            <Comments />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
