import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  MdKeyboardArrowLeft,
  MdOutlineDateRange,
  MdOutlineMail,
} from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

const BookingDetails = () => {
  const [booking, setBooking] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    const token = sessionStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `https://apiv1.gadgetsreborn.com:8899/booking-service/service-request/booking-details?bookingUUid=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      setBooking(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="sm:mx-[40px] mb-[60px]">
      <Navbar />
      <div className="min-h-screen sm:mx-[30px] mx-[20px]">
        <div className="flex items-center">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="">
              <MdKeyboardArrowLeft className="h-10 w-10" />
            </span>
          </button>
          <div className="text-2xl font-bold my-[32px]">Repair Details</div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6">
          <div className="flex gap-[14px]">
            {/* <img className="object-cover" src={Profile} alt="" /> */}
            <div>
              <h3 className="text-[#101828] text-[18px] font-semibold">
                {booking?.address?.name || "N/A"}
              </h3>
              <p className="text-[#475467] text-sm">
                ID: {booking?.serviceUuid || "N/A"}
              </p>
            </div>
          </div>
          <div className="mt-4 flex sm:flex-row flex-col sm:gap-[42px] gap-3">
            <div className="flex flex-col justify-center">
              <div className="flex gap-[6px]">
                <MdOutlineMail size={18} color="#E4B703" />
                <h3 className="text-sm font-bold text-[#475467]">Email</h3>
              </div>
              <p className="text-[12px] text-[#475467]">
                {booking?.address?.email || "N/A"}
              </p>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex gap-[6px]">
                <FaPhoneAlt size={16} color="#E4B703" />
                <h3 className="text-sm font-bold text-[#475467]">
                  Phone Number
                </h3>
              </div>
              <p className="text-[12px] text-[#475467]">
                {booking?.address?.mobileNo || "N/A"}
              </p>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex gap-[6px]">
                <MdOutlineDateRange size={18} color="#E4B703" />
                <h3 className="text-sm font-bold text-[#475467]">Date</h3>
              </div>
              <p className="text-[12px] text-[#475467]">
                {new Date(booking?.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="mt-4 border-t-[2px]">
            <div className="mt-4">
              <h3 className="text-sm text-[#475467] font-bold">
                Device details
              </h3>
              <div className="mt-[8px]">
                <h3 className="text-[#101828] text-[16px] font-bold">
                  {booking?.modelName || "N/A"}
                </h3>
                <p className="text-[12px] text-[#344054]">
                  Type:
                  <span className="font-semibold">
                    {" "}
                    {booking?.categoryName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Brand:
                  <span className="font-semibold">
                    {" "}
                    {booking?.brandName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Series:
                  <span className="font-semibold">
                    {" "}
                    {booking?.modelName || "N/A"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
          <div className="grid grid-cols-2 items-center justify-center">
            <div className="col-span-1 ">
              <p className="font-bold text-sm text-[#475467]">Description</p>
              <h3 className="text-[#101828] text-[16px] font-bold mt-1">
                {booking?.defectName || "N/A"}
              </h3>
            </div>
          </div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
          <div className="grid grid-cols-2 items-center justify-center">
            <div className="col-span-1 ">
              <p className="font-bold text-sm text-[#475467]">Address</p>
              <p className="text-[12px] text-[#101828]  mt-1">
                {booking?.address?.apartment}, {booking?.address?.landMark},{" "}
                {booking?.address?.locality}, {booking?.address?.zipCode}
              </p>
            </div>
          </div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
          <p className="font-bold text-sm text-[#475467]">Payment details</p>
          <div className="flex justify-between">
            <p className="text-[12px]">Total </p>
            <p className="text-[12px] font-semibold">
              {booking?.totalAmount || "N/A"}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-[12px]">Discount</p>

            <p className="text-[12px] font-semibold">{booking?.discount}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-[12px]">Amount paid</p>
            <p className="text-[12px] font-semibold">{booking?.amountPaid}</p>
          </div>
          <div className="mt-4 border-t-[2px] py-4">
            <div className="flex justify-between">
              <p>Amount to be paid</p>
              <h3 className="text-[28px]">
                {booking?.currentdueAmount || "N/A"}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
