import React, { useEffect } from 'react';
import Integration from '../components/defects/Integration';
import Footer from '../components/Footer';

const DefetsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col sm:mx-[50px]">
      <Integration />
      <Footer />
    </div>
  );
}

export default DefetsPage;
