import React from "react";
import Slider from "react-slick";
import stars from "./styles/Group 69.png";
import logo1 from "./styles/Logo wrap - Copy.png";
import logo2 from "./styles/Logo wrap(1) - Copy.png";
import logo3 from "./styles/Logo wrap(2) - Copy.png";
import logo4 from "./styles/Logo wrap(3) - Copy.png";
import logo5 from "./styles/Logo wrap(4) - Copy.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsRepair = () => {
  const testimonials = [
    {
      name: "Emily R.",
      location: "MBR City, Dubai",
      text: "I was in a real fix when my phone suddenly died on me. I rely on it for work and couldn't be without it. I called Gadgets Reborn, and they were a lifesaver. They picked up my phone, fixed it within a few hours, and dropped it back off. The technician kept me updated on the progress, which really put me at ease. Kudos to the GR Team!!",
    },
    {
      name: "Edward W. Rize",
      location: "Marina, Dubai",
      text: "I've never experienced such a seamless repair service before. I've lived in many countries, and when my phone’s screen cracked, I dreaded the thought of finding time to visit a repair shop. So, quickly, I searched and found Gadgets Reborn. This company picked up my phone from my office, repaired it, and returned it the same day. The convenience and professionalism were top-notch. I highly recommend their services to anyone in Dubai!",
    },
    {
      name: "Graham V.",
      location: "JBR, Dubai",
      text: "Fantastic service! My phone had a battery issue, and I was worried about the inconvenience of getting it fixed. Gadgets Reborn picked up my phone from my doorstep, fixed it quickly, and returned it in no time. The staff was incredibly friendly and communicative. I’m so relieved to have my phone working again without any disruption to my day.",
    },
    {
      name: "Ahmed Al Y.",
      location: "DIP, Dubai",
      text: "I can't thank this team enough for their amazing service. My phone was water-damaged, and I was sure it was beyond repair. I got a reference from Gadgets Reborn about their skillful technicians, and the experience was spot on. They worked their magic and returned my phone fully functional. You guys are true phone saviors! If anyone needs their phone fixed in Dubai, this is the place to go!",
    },
  ];

  const logos = [logo1, logo2, logo3, logo4, logo5];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="justify-center bg-Yellow rounded-gr flex flex-col py-[60px] mt-12 items-center sm:mx-0 mx-[20px]">
      <div className="w-full flex flex-col text-center lg:gap-[35px] gap-[30px] font-grFont items-center">
        <img src={stars} className="h-auto w-[250px]" alt="Stars" />
        <h1 className="lg:text-[48px] text-[32px] font-[700] lg:leading-[60px] leading-[44px] tracking-[2%] px-2 sm:px-0">
          From Frustration to Fix: Real Stories from <br /> Our Customers
        </h1>
        <Slider {...settings} className="max-w-[900px] w-full">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="text-center px-4">
              <p className="lg:leading-[44px] lg:text-[20px] leading-[30px] text-[20px] font-[500] lg:font-[400] tracking-[-2%] mb-4">
                {testimonial.text}
              </p>
              <div className="flex flex-col items-center gap-[10px] text-center">
                {/* <img src={author1} className="h-16 w-16" alt="Author" /> */}
                <p className="self-center font-bold text-[20px]">
                  {testimonial.name}, {testimonial.location}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsRepair;
