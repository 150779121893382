import React from "react";
import exampleIcon from "../../pages/Address/assets/icon.png"; // Replace with the correct path to your icon

const Issues = ({ title, brand, model, price, storage, icon = exampleIcon }) => {
  return (
    <div
      className={`rounded-[12px] bg-[#FFFFFF] self-center lg:self-auto flex flex-row justify-start cursor-pointer border-[#D0D5DD] border-[1px] py-4`}
    >
      <div className="flex flex-row gap-[15px]">
        {/* Icon Display */}
        {icon && (
          <div className="flex items-start ml-4">
            <img src={icon} alt="icon" className="h-12 w-12" />
          </div>
        )}
        <div className="flex flex-col">
          <div className="font-[700] text-[20px] leading-[24px] text-[#344054]">
            {title}
          </div>
          <div className="mt-2 space-y-2">
            <div className="text-[#475467] text-[16px] leading-[18px] font-[400] ">
              <strong >Brand:</strong> {brand}
            </div>
            <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
              <strong>Series:</strong> {model}
            </div>
            <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
              <strong>Device Value:</strong> AED {price}
            </div>
            <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
              <strong>Storage:</strong> {storage}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Issues;
