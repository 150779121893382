import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BannerImage from "./../../assets/banner.jpeg"

function Banner() {
  return (
    <div className="sm:mx-[50px]">
      <div className="bg-[#F9FAFB] ">
        <Navbar />
        <div className="pb-[70px]">
          <div className="sm:mx-[30px] mx-[20px] mt-[36px]">
            <img src={BannerImage} className="w-full rounded-lg" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="my-10">
              <p className="text-[#475467] font-inter text-sm">No Active Plans. Get Started with a New Care Plan.</p>
            </div>
            <button className="bg-[#FBC903] text-[16px] font-medium rounded-[8px] text-center py-[12px] w-[250px]">
              Explore Care Plans
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Banner;
