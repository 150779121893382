import React from "react";
import Navbar from "../Navbar";
import MobileNav from "./MobileNav";
import DefectTwo from "./DefectTwo";
import ScreenNav from "./ScreenNav";
import Comments from "./Comments";

const Integration = () => {
  return (
    <div className="bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-center gap-12 mt-16 px-6 pb-24">
        <div className="flex lg:flex-row flex-col gap-4">
          <div className="flex flex-col gap-8">
            <div className="lg:hidden block mb-[-20px]">
              <MobileNav />
            </div>
            <DefectTwo />
          </div>
          <div className="flex flex-col gap-10 sm:ml-5 lg:ml-10 lg:border-l-2 lg:pl-8">
            <ScreenNav />
            <Comments />
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Integration;
