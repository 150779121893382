import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Table, message, Pagination } from "antd";
import axios from "axios";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import Loader from "../../components/loader/Loader";

function CareHistory() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0); // Dynamic page number
  const [pageSize, setPageSize] = useState(20); // Dynamic page size
  const [totalItems, setTotalItems] = useState(0); // Total items for pagination
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookingData = async () => {
      setLoading(true);
      try {
        const token = sessionStorage.getItem("authToken");
        if (!token) {
          message.error("Authentication token is missing.");
          return;
        }

        const options = {
          method: "GET",
          url: "https://apiv1.gadgetsreborn.com:8899/booking-service/care/all-booking-of-user",
          params: { pageNo, pageSize },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const { data } = await axios.request(options);
        console.log("Booking data:", data);
        if (data && data.data && data.data.content) {
          const bookings = data.data.content.map((booking, index) => ({
            key: index + 1,
            sno: index + 1,
            bookingId: booking.serviceUuid,
            date: new Date(booking.createdAt).toLocaleDateString(),
            category: booking.categoryName
              ? booking.categoryName.trim()
              : "N/A",
            brand: booking.brandName ? booking.brandName.trim() : "N/A",
            model: booking.modelName ? booking.modelName.trim() : "N/A",
            status: booking.careStatus
              ? booking.careStatus?.replace("_", " ")
              : "N/A",
            amount: booking.totalAmount,
          }));
          setDataSource(bookings);
          setTotalItems(data.data.totalItems); // Set total items for pagination
        } else {
          message.error("No booking data available.");
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
        message.error("An error occurred while fetching booking data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBookingData();
  }, [pageNo, pageSize]);

  const handleClaim = (record) => {
    // Handle the claim action here
    console.log("Claim action for record:", record);
  };

  const handleRowClick = (record) => {
    navigate(`/care-history/${record.bookingId}`);
  };

  const columns = [
    {
      title: "S/NO",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (text, record) => (
        <Link to={`/care-history/${record.bookingId}`}>{text}</Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          onClick={() => handleClaim(record)}
          className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold rounded-md shadow-sm"
        >
          Claim
        </button>
      ),
    },
    {
      title: "",
      key: "arrow",
      render: (text, record) => (
        <Link to={`/care-history/${record.bookingId}`}>
          <MdKeyboardArrowRight size={20} />
        </Link>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setPageNo(page - 1); // API expects 0-based page number
    setPageSize(pageSize);
  };
  return (
    <div>
      <Loader loading={loading} />
      {dataSource.length === 0 && loading ? (
        <Table dataSource={[]} columns={columns} />
      ) : dataSource.length === 0 ? (
        <Banner />
      ) : (
        <div className="lg:mx-[40px]">
          <Navbar />
          <div className="min-h-screen sm:block hidden">
            <div className="flex items-center mx-4 md:mx-8 lg:mx-16">
              <div className="text-xl md:text-2xl font-bold my-8">
                Care History
              </div>
            </div>
            <div className="border rounded-lg md:rounded-2xl shadow py-3 px-4 md:px-6 lg:px-8 mx-[40px]">
              <Table
                dataSource={dataSource}
                columns={columns}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
                pagination={{
                  current: pageNo + 1,
                  pageSize,
                  total: totalItems, // Set total items for pagination
                  showSizeChanger: false, // Hide the page size changer dropdown
                  onChange: (page) => {
                    setPageNo(page - 1); // Ant Design's pagination is 1-based, but the API is 0-based
                  },
                }}
                // loading={loading}
                scroll={{ x: 800 }}
              />
            </div>
          </div>

          {/* Mapping the card content from the API data */}
          <div className="text-xl md:text-2xl font-bold sm:hidden block my-4 ml-6 md:ml-8 lg:ml-16">
            Care History
          </div>
          {dataSource.map((booking) => (
            <Link to={`/care-history/${booking.bookingId}`} key={booking.key}>
              <div
                key={booking.key}
                className="bg-white block sm:hidden p-4 rounded-md shadow mt-4 border cursor-pointer mx-[24px]"
              >
                <div>
                  <h3 className="text-[#101828] text-[18px] font-bold">
                    {booking.model}
                  </h3>
                </div>
                <div className="flex justify-between items-center border-b-2 pb-2">
                  <p className="text-[#475467] font-medium">
                    {booking.bookingId}
                  </p>
                  <p className="text-[#475467] font-medium">{booking.date}</p>
                </div>
                <div className="flex justify-between items-center py-2">
                  <p className="text-[#101828] text-[18px] font-semibold">
                    {booking.brand}
                  </p>
                  <p className="text-[#101828] text-[18px] font-semibold">
                    AED {booking.amount}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <span
                    className={`bg-[#FBC903] font-semibold text-sm text-[#101828] px-4 py-1 rounded-[6px]`}
                  >
                    {booking.status}
                  </span>
                  <button
                    onClick={() => handleClaim(booking)}
                    className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold rounded-md shadow-sm"
                  >
                    Claim
                  </button>
                </div>
              </div>
            </Link>
          ))}
          <div className="sm:hidden block mx-[24px]">
            <Pagination
              current={pageNo + 1}
              pageSize={pageSize}
              total={totalItems} // Set total items for pagination
              onChange={handlePageChange}
              showSizeChanger={false} // Hide the "page size" selector
              className="my-4" // Optional: Add margin to separate pagination from content
            />
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default CareHistory;
