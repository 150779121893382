import React, { useEffect } from 'react'
import Address from '../components/address/Address'
import Integration from '../components/address/Integration'
import Footer from '../components/Footer'

const AddressPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div  className="sm:mx-[50px]">
    <Integration/>
    <Footer/>
  </div>
  )
}

export default AddressPage