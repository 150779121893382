// src/components/AuthGuard.js

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const location = useLocation();
  const authToken = sessionStorage.getItem('authToken');

  // If there's an authToken, redirect to home
  if (authToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Otherwise, render the children components
  return children;
};

export default AuthGuard;
