import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import LocationIcon from "./assets/Group 8796.png";
import { useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import SearchIcon from "./assets/Search_alt.png";
import ReCAPTCHA from "react-google-recaptcha";
import MobileNav from "../../components/Address/MobileNav";
import Issues from "../../components/Issues/Issues";
import ScreenNav from "../../components/Address/ScreenNav";
import Comments from "../../components/SelectDevice/Comments";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import Issue from "../../../components/category/Issue";
import img from "../../../assets/Group 8805.webp";
import { Checkbox, Spin, message } from "antd";
import axios from "axios";

const libraries = ["places", "marker"];

const Address = () => {
  const authToken = sessionStorage.getItem("authToken");
  const [checked, setChecked] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (sessionStorage.getItem("userEmail")) {
      setShowMobileInput(true);
    }
    if (sessionStorage.getItem("userPhoneNumber")) {
      setShowEmailInput(true);
    }
  }, []);

  useEffect(() => {
    // console.log("location.state:", location.state);
  }, [location.state]);

  useEffect(() => {
    const bookCarePlan = async () => {
      try {
        // Check if careServiceUuid is already present in session storage
        const existingCareServiceUuid =
          sessionStorage.getItem("careServiceUuid");
        if (existingCareServiceUuid) {
          // console.log(
          //   "careServiceUuid already present:",
          //   existingCareServiceUuid
          // );
          const carepurchaseMonth = sessionStorage.getItem("carepurchaseMonth");
          // console.log("carePurchaseMonth:", carepurchaseMonth);
          return;
        }

        // Retrieve data from session storage
        const careDetails = {
          careName: sessionStorage.getItem("careName"),
          carecategorySkuCode: sessionStorage.getItem("carecategorySkuCode"),
          carebrandName: sessionStorage.getItem("carebrandName"),
          cardPrice: sessionStorage.getItem("cardPrice"),
          caredeviceValue: sessionStorage.getItem("caredeviceValue"),
          careDescription: sessionStorage.getItem("planDesc"),
          carepurchaseMonth: sessionStorage.getItem("purchaseDuration"),
          carebrandSkuCode: sessionStorage.getItem("carebrandSkuCode"),
          careSkuCode: sessionStorage.getItem("careSkuCode"),
          careCategoryName: sessionStorage.getItem("careCategoryName"),
          caremodelSkuCode: sessionStorage.getItem("caremodelSkuCode"),
          caremodelName: sessionStorage.getItem("caremodelName"),
          carestorageSkuCode: sessionStorage.getItem("careStorageSkuCode"),
          carestorageName: sessionStorage.getItem("careStorageName"),
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}:8899/booking-service/care/book-careplan`,
          {
            categorySku: parseInt(careDetails.carecategorySkuCode),
            categoryName: careDetails.careCategoryName,
            brandSku: parseInt(careDetails.carebrandSkuCode),
            brandName: careDetails.carebrandName,
            modelSku: parseInt(careDetails.caremodelSkuCode),
            modelName: careDetails.caremodelName,
            careSku: parseInt(careDetails.careSkuCode),
            careName: careDetails.careName,
            planDesc: careDetails.careDescription,
            purchaseDuration: careDetails.carepurchaseMonth,
            storageSku: parseInt(careDetails.carestorageSkuCode),
            storageName: careDetails.carestorageName,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const { serviceUuid } = response.data;
        sessionStorage.setItem("careServiceUuid", serviceUuid);
      } catch (error) {
        console.error("Error booking care plan:", error);
      }
    };

    bookCarePlan();
  }, []);

  const { estimatedPrice, name } = location.state ?? {};
  const state = location?.state ?? {}; // Add default empty object if null
  const { brand, model, value, purchaseDuration } = state;

  const PlanPrice = sessionStorage.getItem("cardPrice");
  const PlanName = sessionStorage.getItem("careName");

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const initialCenter = {
    lat: 25.276987,
    lng: 55.296249,
  };

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(initialCenter);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [additionalAddress, setAdditionalAddress] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [mainAddressCountry, setMainAddressCountry] = useState("");
  const [additionalAddressCountry, setAdditionalAddressCountry] = useState("");
  const [geoFenceError, setGeoFenceError] = useState(""); // Add this state
  const [showMobileInput, setShowMobileInput] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false); // Add this state
  const [couponStatus, setCouponStatus] = useState("");
  const [afterPrice, setAfterPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchBoxRef = useRef(null);
  const navigate = useNavigate();

  const handleLoad = async (mapInstance) => {
    // console.log("Map loaded:", mapInstance);
    setMap(mapInstance);

    // Center the map on initial load
    mapInstance.setCenter(initialCenter);
    mapInstance.setZoom(10); // Set initial zoom level

    const input = document.getElementById("address-search");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "ae" }, // Restrict to UAE
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) return;

      const newLocation = place.geometry.location;
      setCenter(newLocation);
      updateMarkerPosition(newLocation);
      setAddress(place.formatted_address);

      // Adjust zoom level and center the map on search
      mapInstance.setZoom(15);
      mapInstance.setCenter(newLocation);
    });

    // Add the initial marker and set map center
    try {
      await addMarker(mapInstance, initialCenter, 10); // Pass the zoom level here
      mapInstance.setCenter(initialCenter); // Ensure map is centered on initial load
    } catch (error) {
      console.error("Error adding marker:", error);
    }
  };
  useEffect(() => {
    if (address.length > 0) setIsAddressSelected(true);
  }, [address]);

  const addMarker = async (mapInstance, position, zoomLevel = 10) => {
    if (!mapInstance) return; // Check if mapInstance is not null

    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );
    const newMarker = new AdvancedMarkerElement({
      map: mapInstance,
      position: position,
      title: "Selected Location",
      draggable: true,
    });

    newMarker.addListener("dragend", async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const newPosition = { lat, lng };

      setCenter(newPosition);
      updateMarkerPosition(newPosition);

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          setAddress(results[0].formatted_address);
          getCountryFromAddress(
            results[0].formatted_address,
            setMainAddressCountry
          );
        }
      });

      if (mapInstance) {
        mapInstance.setZoom(15); // Ensure mapInstance is not null before using
        mapInstance.setCenter(newPosition);
      }
    });

    setMarker(newMarker);
    if (mapInstance) {
      mapInstance.setZoom(zoomLevel); // Ensure mapInstance is not null before using
    }
  };

  const updateMarkerPosition = (position) => {
    if (marker) {
      marker.position = position;
    } else {
      addMarker(map, position);
    }
  };

  useEffect(() => {
    if (map) {
      const handleClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newCenter = { lat, lng };

        setCenter(newCenter);
        updateMarkerPosition(newCenter);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: newCenter }, (results, status) => {
          if (status === "OK" && results[0]) {
            setAddress(results[0].formatted_address);
            getCountryFromAddress(
              results[0].formatted_address,
              setMainAddressCountry
            );
          }
        });

        map.setZoom(15);
        map.setCenter(newCenter);
      };

      map.addListener("click", handleClick);

      return () => {
        window.google.maps.event.clearListeners(map, "click");
      };
    }
  }, [map, marker]);

  const getCountryFromAddress = (address, setCountry) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );
        if (countryComponent) {
          setCountry(countryComponent.long_name);
        }
      }
    });
  };

  const handleZoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  };

  const handleZoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(currentLocation);
        updateMarkerPosition(currentLocation);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: currentLocation }, (results, status) => {
          if (status === "OK" && results[0]) {
            setAddress(results[0].formatted_address);
            getCountryFromAddress(
              results[0].formatted_address,
              setMainAddressCountry
            );
          }
        });

        map.setZoom(15);
        map.setCenter(currentLocation);
      });
    }
  };

  const isValidMobileNumber = (number) => {
    return number.startsWith("9715") && number.length === 12;
  };

  const handleCaptchaVerify = (value) => {
    if (value) {
      setCaptchaVerified(true);
      setCaptchaToken(value);
    }
  };

  const handleAdditionalAddressChange = (e) => {
    const value = e.target.value;
    setAdditionalAddress(value);
    if (value) {
      getCountryFromAddress(value, setAdditionalAddressCountry);
    }
  };

  const handleCouponCodeChange = (e) => {
    const value = e.target.value;
    setCouponCode(value);
  };

  const handleApplyCoupon = async (e) => {
    e.preventDefault();

    try {
      const storedPrice = sessionStorage.getItem("cardPrice");
      const authToken = sessionStorage.getItem("authToken");
      if (couponCode && storedPrice) {
        const options = {
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/coupans/validate`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: { couponCode, price: parseFloat(storedPrice) },
        };
        const { data } = await axios.request(options);
        // console.log("Coupon validation response:", data);
        setAfterPrice(data.afterPrice);
        setCouponStatus("Coupon code applied");
      } else {
        setCouponStatus("Coupon code not provided or price missing");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        setCouponStatus("Invalid coupon code");
      } else {
        console.error("Error validating coupon code:", error);
      }
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();

    const serviceRequestId = sessionStorage.getItem("careServiceUuid");

    if (mainAddressCountry !== additionalAddressCountry) {
      setGeoFenceError(
        "The main address and additional address must be in the same country."
      );
      return;
    } else {
      setGeoFenceError(""); // Clear the error if countries match
    }

    setLoading(true); // Start the loader

    try {
      const name = `${firstName} ${lastName}`;
      const emailValue = sessionStorage.getItem("userEmail") || email; // Assuming email is a state variable
      const mobileNoValue =
        sessionStorage.getItem("userPhoneNumber") || mobileNumber; // Assuming mobileNumber is a state variable
      const locality = address; // Assuming address is a state variable
      const landMark = additionalAddress; // Assuming additionalAddress is a state variable
      const zipCode = ""; // Optional zip code
      const latitude = []; // Optional latitude values
      const longitude = []; // Optional longitude values
      const apartmentValue = apartment; // Assuming apartment is a state variable

      // First API call
      const addressOptions = {
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/care/assign-address`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "application/json",
        },
        data: {
          name,
          email: emailValue,
          mobileNo: mobileNoValue,
          serviceRequestId,
          locality,
          landMark,
          zipCode,
          latitude,
          longitude,
          apartment: apartmentValue,
        },
      };

      const addressResponse = await axios.request(addressOptions);
      // console.log(addressResponse.data);

      // Second API call
      const paymentOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}:9009/payment-service/api/payment/make`,
        params: {
          bookingId: serviceRequestId,
          ...(couponCode &&
            couponStatus === "Coupon code applied" && { couponCode }), // Include coupon code if valid
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const paymentResponse = await axios.request(paymentOptions);

        // Redirect user to payment URL if it's a valid URL
        if (paymentResponse && paymentResponse.data) {
          window.location.href = paymentResponse.data;
        } else {
          console.error("Invalid payment URL received.");
        }
      } catch (error) {
        console.error("Error processing payment:", error);
      }
    } catch (error) {
      console.error("Error assigning address:", error);
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  // navigate("/care-flow/booking-confirmed", {
  //   state: {
  //     ...location.state,
  //     estimatedPrice,
  //     name,
  //     apartment,
  //     address,
  //     additionalAddress,
  //     fullName,
  //     mobileNumber,
  //     email,
  //   },
  // });

  const handleGoBack = () => {
    navigate(-1);
  };
  const handlePhoneChange = () => {
    navigate("/care/select-device");
  };

  const handlePlanChange = () => {
    navigate("/care/estimated-price");
  };

  const category = sessionStorage.getItem("careCategoryName");
  const Brand = sessionStorage.getItem("carebrandName");
  const Model = sessionStorage.getItem("caremodelName");
  const Value = sessionStorage.getItem("caredeviceValue");
  const storage = sessionStorage.getItem("careStorageName");

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      const authToken = sessionStorage.getItem("authToken");
      const serviceId = sessionStorage.getItem("careServiceUuid"); // Get serviceRequestId from session storage

      if (!serviceId) {
        message.error("Service Request ID not found in session storage.");
        return;
      }

      try {
        const response = await axios.put(
          `https://apiv1.gadgetsreborn.com:8899/booking-service/care/take-consent?serviceId=${serviceId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        message.success("Consent taken successfully!");
      } catch (error) {
        console.error("Error taking consent:", error);
        message.error("Failed to take consent.");
      }
    }
  };
  return (
    <div className="">
      <div className="bg-[#F9FAFB]  pt-[16px] px-[30px] pb-[90px] min-h-[680px] rounded-b-[40px] sm:mx-[60px]">
        <div>
          <Navbar />
        </div>
        <div className="lg:hidden flex justify-center items-center my-8">
          <MobileNav />
        </div>
        <button
          onClick={handleGoBack}
          className="hidden ml-[48px] my-[60px] px-4 mb-4 py-[6px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
        >
          <span className="">
            <MdKeyboardArrowLeft />
          </span>
          <span className="font-bold whitespace-nowrap font-plus-jakarta-sans text-[14px] leading-[20px]">
            Go Back
          </span>
        </button>

        <div className="grid sm:grid-cols-6 grid-cols-1 justify-center sm:mt-[60px] xl:mt-[40px]">
          <div className="col-span-4 sm:ml-[18px] ">
            <div className="text-left">
              <h1 className="sm:text-4xl text-3xl font-plus-jakarta-sans sm:text-[36px] text-[#101828] sm:text-center text-left lg:text-left font-bold leading-[44px]  max-w-[300px] lg:max-w-[500px]">
                Select your address
              </h1>
              <p className="text-gray-500 font-plus-jakarta-sans mt-[8px]">
                Where should we come to collect the device?
              </p>
            </div>
            <div className="sm:mt-[40px] mt-[24px] sm:mr-[32px] grid sm:gap-[30px] sm:grid-cols-2 grid-cols-1">
              <div className="relative w-full sm:max-w-[370px] h-96 sm:h-[700px] lg:max-w-[500px] lg:h-[725px]">
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  libraries={libraries}
                  id="script-loader"
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={handleLoad}
                    options={{
                      fullscreenControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      streetViewControl: false,
                      mapId: "DEMO_MAP_ID",
                    }}
                  />
                </LoadScript>
                <div className="absolute top-6 left-8 right-8 flex items-center">
                  <img
                    src={SearchIcon}
                    alt="Search"
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
                  />
                  <input
                    id="address-search"
                    type="text"
                    placeholder="Search address"
                    className="w-full pl-10 p-2 rounded-md focus:outline-none border border-[#D0D5DD] shadow-md placeholder-[#D0D5DD]"
                    ref={searchBoxRef}
                  />
                </div>
                <div className="absolute bottom-4 right-4 flex items-center space-x-2">
                  <button
                    onClick={handleZoomIn}
                    className="bg-white p-2 w-8 h-8 rounded shadow-md"
                  >
                    +
                  </button>
                  <button
                    onClick={handleZoomOut}
                    className="bg-white p-2 w-8 h-8 rounded shadow-md"
                  >
                    -
                  </button>
                  <button
                    onClick={handleCurrentLocation}
                    className="bg-white p-2 w-36 h-8 rounded shadow-md flex items-center whitespace-pre font-plus-jakarta-sans text-sm"
                  >
                    <img
                      src={LocationIcon}
                      alt="Current Location"
                      className="w-5 h-5 mr-2"
                    />
                    Current location
                  </button>
                </div>
              </div>
              <div className="sm:mt-0 mt-[24px]">
                {isAddressSelected && (
                  <div className=" bg-white p-8 rounded-lg shadow-md border border-[#D0D5DD]">
                    <form className="flex flex-col gap-4">
                      <div className="mb-4">
                        <label
                          htmlFor="apartment"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Apartment / Villa*
                        </label>
                        <input
                          type="text"
                          id="apartment"
                          className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                          placeholder="eg-SH-17"
                          value={apartment}
                          onChange={(e) => setApartment(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Your Address*
                        </label>
                        <input
                          type="text"
                          id="address"
                          className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                          placeholder="Al Shamkhah – Abu Dhabi"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="additional-address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Additional Address
                        </label>
                        <input
                          type="text"
                          id="additional-address"
                          className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                          placeholder="Enter your address here"
                          value={additionalAddress}
                          onChange={handleAdditionalAddressChange}
                        />
                        {geoFenceError && (
                          <p className="text-red-600 mt-2">{geoFenceError}</p>
                        )}{" "}
                        {/* Warning message */}
                      </div>
                      <h2 className="text-base font-bold text-[#344054]">
                        Additional Information
                      </h2>
                      <div className="flex space-x-4 mb-4">
                        <div className="flex-1">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name*
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                            placeholder="Varun"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="flex-1">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name*
                          </label>
                          <input
                            type="text"
                            id="last-name"
                            className="mt-1 block w-full rounded-md h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                            placeholder="Sunara"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-2">
                        {" "}
                        {showEmailInput ? (
                          <>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email*
                            </label>
                            <input
                              type="email"
                              id="email"
                              className="mt-1 block w-full rounded-md focus:outline-none h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                              placeholder="eg- user@example.com"
                              value={email} // Change to email state
                              onChange={(e) => setEmail(e.target.value)} // Update email state
                              required
                            />
                          </>
                        ) : showMobileInput ? (
                          <>
                            <label
                              htmlFor="mobile-number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Mobile number*
                            </label>
                            <input
                              type="text"
                              id="mobile-number"
                              className="mt-1 block w-full rounded-md focus:outline-none h-10 border border-[#D0D5DD] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-[#667085] pl-2"
                              placeholder="9715XXXXXXXX"
                              value={mobileNumber}
                              onChange={(e) => {
                                // Only update the state if the new value has at most 12 digits and is numeric
                                const newValue = e.target.value.replace(
                                  /\D/g,
                                  ""
                                ); // Remove non-numeric characters
                                if (newValue.length <= 12) {
                                  setMobileNumber(newValue);
                                }
                              }}
                              required
                              maxLength={12} // Limit the input length to 12 characters
                            />
                          </>
                        ) : null}
                      </div>
                      <div className="">
                        <label
                          htmlFor="coupon-code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Coupon Code (Optional)
                        </label>
                        <div className="flex items-center space-x-2">
                          <input
                            type="text"
                            value={couponCode}
                            onChange={handleCouponCodeChange}
                            placeholder="Enter coupon code"
                            className="p-2 border border-gray-300 rounded-md"
                          />
                          <button
                            onClick={handleApplyCoupon}
                            className="bg-yellow-500 text-white p-2 w-full rounded-md"
                          >
                            Apply
                          </button>
                        </div>
                        {couponStatus && (
                          <div className="mt-2 flex items-center space-x-2">
                            <p
                              className={`${
                                couponStatus === "Coupon code applied"
                                  ? "text-green-500 text-sm"
                                  : "text-red-500"
                              }`}
                            >
                              {couponStatus === "Coupon code applied"
                                ? "Your coupon code has been successfully applied and will be used during the final payment process."
                                : couponStatus}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center justify-center space-x-2">
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckboxChange}
                          className="text-red-700"
                        />
                        <p className="text-center text-sm text-gray-700 underline cursor-pointer font-semibold">
                          <a
                            href="https://gr-static-file.s3.amazonaws.com/tandc/tc.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions
                          </a>
                        </p>
                      </div>
                      {/* <ReCAPTCHA
                      sitekey=""
                      onChange={handleCaptchaVerify}
                    /> */}
                      <button
                        disabled={
                          !apartment ||
                          !address ||
                          !firstName ||
                          !lastName ||
                          !isValidMobileNumber(mobileNumber) ||
                          mainAddressCountry !== additionalAddressCountry ||
                          !checked ||
                          loading // Disable the button when loading
                        }
                        type="submit"
                        className="w-full px-6 py-3 bg-yellow-500 hover:bg-[#667085] disabled:opacity-50 disabled:cursor-not-allowed text-white font-semibold rounded-md shadow-sm"
                        onClick={handleContinue}
                      >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <Spin size="small" className="mr-2" />
                            Loading...
                          </div>
                        ) : (
                          "Proceed to checkout"
                        )}
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-2 sm:border-l sm:px-[42px]">
            <div className="lg:block hidden self-center mb-[-20px]">
              <ScreenNav />
            </div>
            <div className="flex flex-row justify-between sm:ml-12 lg:ml-0 mt-8">
              <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                Your Plan Details
              </p>
              <button
                onClick={handlePlanChange}
                className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
              >
                Change
              </button>
            </div>
            <div className="mt-3">
              <div
                className={`rounded-[12px] bg-[#FFFFFF] self-center lg:self-auto flex flex-row justify-start cursor-pointer border-[#D0D5DD] border-[1px] py-4`}
              >
                <div className="flex flex-row">
                  <div className="flex items-start ml-4">
                    <img src={img} alt="icon" className="h-8 w-8" />
                  </div>
                  <div className="flex flex-col ml-5">
                    <div className="font-[700] text-[20px] leading-[24px] text-[#344054]">
                      {PlanName}
                    </div>
                    <div className="mt-2  space-y-2">
                      <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
                        <strong>Plan Amount:</strong>{" "}
                        <span>AED {Math.round(PlanPrice)} </span>
                      </div>
                      {afterPrice && (
                        <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
                          <strong>Discount Amount:</strong>{" "}
                          <span>AED {(PlanPrice - afterPrice).toFixed(2)}</span>
                        </div>
                      )}
                      <div className="text-[#475467] text-[16px] leading-[18px] font-[400] ">
                        <strong>Total Amount:</strong>{" "}
                        {afterPrice ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: "8px",
                              }}
                            >
                              AED {Math.round(PlanPrice)}
                            </span>
                            <span>AED {afterPrice} </span>
                          </>
                        ) : (
                          <span>AED {Math.round(PlanPrice)} </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4 pb-8 border-b">
              <div className="flex flex-row justify-between sm:ml-12 lg:ml-0 mt-4">
                <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                  Your Device
                </p>
                <button
                  onClick={handlePhoneChange}
                  className="text-[#B28F02] font-[700] text-[14px] leading-[20px] font-grFont cursor-pointer"
                >
                  Change
                </button>
              </div>
              <div className="mt-3">
                <Issues
                  title={category || "Category Name"}
                  brand={Brand || "Brand Name"}
                  model={Model || "Model Name"}
                  price={Value || "Device Value"}
                  storage={storage || "Device Storage"}
                />
              </div>
            </div>
            <Comments />
          </div>
        </div>
      </div>
      <div className="sm:mx-[60px] mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default Address;
