import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Issue from "../category/Issue";
import MobileNav from "./MobileNav";
import ScreenNav from "./ScreenNav";
import Comments from "../category/Comments";
import Address from "./Address";
import YourDevice from "../category/YourDevice";
import { useNavigate, useLocation } from "react-router-dom";

const Integration = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { brandName, modelName, name, description, imageUrl } =
    location.state || {};

  // States to store PlanPrice and afterPrice
  const [PlanPrice, setPlanPrice] = useState(null);
  const [afterPrice, setAfterPrice] = useState(null);

  // Function to fetch session storage values
  const fetchSessionData = () => {
    const selectedPrice = sessionStorage.getItem("selectedPrice");
    const afterPriceValue = sessionStorage.getItem("afterPrice");
    setPlanPrice(selectedPrice);
    setAfterPrice(afterPriceValue);
  };
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("afterPrice");
    };
  }, []);

  useEffect(() => {
    // Initial fetch of session storage values
    fetchSessionData();

    // Polling every second to check for updates in session storage
    const intervalId = setInterval(() => {
      fetchSessionData();
    }, 1000); // Adjust the interval as needed

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  let Title1 = brandName || "apple";
  const categoryName = sessionStorage.getItem("categoryName");
  let Type = categoryName;
  let Brand = brandName || "apple";
  let Model = modelName || "iphone";
  let Title2 = name || "apple";
  let Description2 = description;

  const handleChange = () => {
    navigate("/repair/defects", { state: { ...location.state } });
  };

  const handlePhoneChange = () => {
    navigate("/repair/category", { state: { ...location.state } });
  };

  const handlePriceChange = () => {
    navigate("/repair/booking-fee", { state: { ...location.state } });
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col pb-6">
      <Navbar />
      <div className="container mx-auto px-4 sm:px-6 lg:px-0 flex flex-col gap-12 mt-8">
        <div className="flex flex-col lg:flex-row gap-8 lg:items-start">
          <div className="lg:hidden block">
            <MobileNav />
          </div>
          <Address />
          <div className="flex flex-col lg:flex-row lg:gap-8 lg:border-l-2 border-t-2 lg:border-t-0">
            <div className="flex flex-col gap-8 lg:gap-12 p-4 lg:pl-8 w-full lg:min-w-[400px]">
              <div className="hidden lg:block">
                <ScreenNav />
              </div>
              <div className="flex mt-[-20px] justify-between items-center mb-2">
                <p className="text-gray-600 font-semibold text-sm">Price</p>
              </div>
              <div className=" mt-[-40px] space-y-2 bg-[#FFFFFF] rounded-[12px] p-4 border border-[#D0D5DD]">
                <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
                  <strong>Plan Amount:</strong> <span> AED {PlanPrice} </span>
                </div>
                {afterPrice && (
                  <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
                    <strong>Discount Amount:</strong>{" "}
                    <span>AED {(PlanPrice - afterPrice).toFixed(2)} </span>
                  </div>
                )}
                <div className="text-[#475467] text-[16px] leading-[18px] font-[400]">
                  <strong>Total Amount:</strong>{" "}
                  {afterPrice ? (
                    <>
                      <span
                        style={{
                          textDecoration: "line-through",
                          marginRight: "8px",
                        }}
                      >
                        AED {PlanPrice}
                      </span>
                      <span>AED {afterPrice} </span>
                    </>
                  ) : (
                    <span> AED {PlanPrice} </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col border-b border-gray-300 pb-8">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-gray-600 font-semibold text-sm">
                    Your Device
                  </p>
                  <button
                    onClick={handlePhoneChange}
                    className="text-yellow-500 font-semibold text-sm cursor-pointer"
                  >
                    Change
                  </button>
                </div>
                <YourDevice
                  phone={Title1}
                  type={Type}
                  Brand={Brand}
                  Model={Model}
                />
                <div className="flex justify-between items-center mt-6 mb-4">
                  <p className="text-gray-600 font-semibold text-sm">
                    Your Issue
                  </p>
                  <button
                    onClick={handleChange}
                    className="text-yellow-500 font-semibold text-sm cursor-pointer"
                  >
                    Change
                  </button>
                </div>
                <Issue
                  title={Title2}
                  description={Description2}
                  icon={imageUrl}
                />
              </div>
              <Comments />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
