import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Integration from '../components/bookingConfirmed/Integration';

const BookingConfirmPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:mx-[50px]">
      <Integration />
      <Footer />
    </div>
  );
};

export default BookingConfirmPage;
