import React from "react";
import usercheck from "../../../../src/components/defects/img/users-check.png";
import building from "../../../../src/components/defects/img/building-02.png";
import checkverified from "../../../../src/components/defects/img/check-verified-01.png";

const Comments = () => {
  return (
    <div className="flex flex-col gap-25 font-[400] text-[16px] leading-[20px] pt-[40px] ">
      <div className="flex gap-4 items-center justify-start">
        <div>
          <img src={usercheck} className="h-[20px] w-[20px] self-center "></img>
        </div>
        <div className="text-[#475467]">Trained Professionals</div>
      </div>
      <div className="flex gap-4 items-center justify-start">
        <div>
          <img src={building} className="h-[20px] w-[20px] self-center"></img>
        </div>
        <div className="text-[#475467]">Doorstep Service</div>
      </div>
      <div className="flex gap-4 items-center justify-start">
        <div>
          <img
            src={checkverified}
            className="h-[20px] w-[20px] self-center"
          ></img>
        </div>
        <div className="text-[#475467]">Guaranteed Safety</div>
      </div>
    </div>
  );
};

export default Comments;
