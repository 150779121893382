import React, { useEffect } from "react";
import Integration from "../components/category/Integration";
import Footer from "../components/Footer";

const BrandModelPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:mx-[50px]">
      <Integration />
      <Footer />
    </div>
  );
};

export default BrandModelPage;
