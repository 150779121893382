import React from "react";
import Slider from "react-slick";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import hero from "../assets/hero.png";
import { LuSend } from "react-icons/lu";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoMdCheckmark } from "react-icons/io";
import { HiArrowUpRight } from "react-icons/hi2";
import plane from "../assets/plane.png";
import superman from "../assets/superman.png";
import magnify from "../assets/magnify.png";
import { GoArrowRight } from "react-icons/go";
import outpost from "../assets/outpost.png";
import { CustomPrevArrow, CustomNextArrow } from "../components/arrows/arrows";

function GrOutpost() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="overflow-hidden sm:mx-[40px] mx-[15px]">
        <div className="bg-[#F0F0F080] bg-opacity-[50%] pb-[34px] rounded-b-[30px]">
          <Navbar />
          <div className="grid sm:grid-cols-2 grid-cols-1 sm:mx-[80px] mx-[30px] items-center justify-center">
            <div className="col-span-1 sm:my-6 my-0">
              <img className="object-cover w-[440px]" src={hero} alt="" />
            </div>
            <div className="col-span-1 text-[#101828]">
              <h3 className="sm:text-[24px] text-[18px] font-medium">
                Become a GR Outpost: Join the Elite Network of Tech Experts
              </h3>
              <h1 className="sm:text-[48px] text-[32px] font-[700] sm:leading-[60px] my-1">
                Ready to Elevate Your Repair Business?
              </h1>
              <p className="sm:text-[18px] text-[14px] font-normal">
                Are you a top-tier repair vendor with a passion for exceptional
                service? Join GR Outpost—a select group of vetted vendors
                leading the way in tech repair.
              </p>
              <div className="flex justify-between mt-[34px] items-center gap-4">
                <div className="flex gap-3 items-center cursor-pointer">
                  <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                    <LuSend size={20} />
                  </div>
                  <div className="whitespace-nowrap sm:text-[18px] text-[14px] font-normal">
                    Learn more
                  </div>
                </div>
                <div>
                  <button
                    className="bg-[#FBC903] text-[#101828] font-semibold text-[16px] sm:rounded-[16px] rounded-[10px] sm:py-[7px] sm:px-[60px] py-[5px] px-[30px]"
                    onClick={() =>
                      (window.location.href =
                        "https://zfrmz.com/m3Z6nmdPqiGuGpEMR6Bv")
                    }
                  >
                    Join now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:mx-[50px] mx-[30px] my-[42px]">
          <h1 className="sm:text-[42px] text-[32px] text-[#101828] font-bold">
            Why Join the GR Outpost Network?
          </h1>
        </div>
        {/* slider starts here */}
        <div className="bg-[#F0F0F080] bg-opacity-[50%] rounded-[30px] pt-[60px]">
          <Slider {...settings} className="">
            <div className="mx-[60px]">
              <div className="flex sm:gap-[40px]">
                <div className="">
                  <h2 className="sm:text-[40px] text-[32px] font-bold">
                    Get Vetted, Get Noticed
                  </h2>
                  <p className="sm:text-[18px] text-[14px] text-[#000000]">
                    As a GR Outpost, join an exclusive network of vetted
                    vendors, earning instant credibility and trust with
                    customers. Stand out with the GR Outpost badge of
                    excellence.
                  </p>
                  <div className="sm:mt-[40px] mt-[20px] space-y-2">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                        <IoMdCheckmark size={20} />
                      </div>
                      <p className="sm:text-[18px] text-[14px]">Vetted</p>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                        <IoMdCheckmark size={20} />
                      </div>
                      <p className="sm:text-[18px] text-[14px]">Credibility</p>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                        <IoMdCheckmark size={20} />
                      </div>
                      <p className="sm:text-[18px] text-[14px]">Excellence</p>
                    </div>
                  </div>
                  <button className="mt-[42px] flex gap-1 items-center justify-center bg-[#FBC903] text-[#101828] font-semibold text-[16px] rounded-[16px] py-[7px] px-[20px]">
                    Learn more <HiArrowUpRight className="font-bold" />
                  </button>
                </div>
                <div>
                  <img
                    className="w-[950px] object-cover"
                    src={outpost}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="mx-[60px]">
              <div className="flex sm:gap-[40px]">
                <div className="">
                  <h2 className="sm:text-[40px] text-[32px] font-bold">
                    Get Vetted, Get Noticed
                  </h2>
                  <p className="sm:text-[18px] text-[14px] text-[#000000]">
                    As a GR Outpost, join an exclusive network of vetted
                    vendors, earning instant credibility and trust with
                    customers. Stand out with the GR Outpost badge of
                    excellence.
                  </p>
                  <div className="mt-[40px] space-y-2">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                        <IoMdCheckmark size={20} />
                      </div>
                      <p className="sm:text-[18px] text-[14px]">Vetted</p>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                        <IoMdCheckmark size={20} />
                      </div>
                      <p className="sm:text-[18px] text-[14px]">Credibility</p>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#FBC903] rounded-full p-[8px] flex items-center justify-center">
                        <IoMdCheckmark size={20} />
                      </div>
                      <p className="sm:text-[18px] text-[14px]">Excellence</p>
                    </div>
                  </div>
                  <button className="my-[42px] flex gap-1 items-center justify-center bg-[#FBC903] text-[#101828] font-semibold text-[16px] rounded-[16px] py-[7px] px-[20px]">
                    Learn more <HiArrowUpRight className="font-bold" />
                  </button>
                </div>
                <div>
                  <img
                    className="sm:w-[950px] w-[300px] object-cover"
                    src={outpost}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        {/* // slider ends here */}
        <div className="bg-[#F0F0F080] bg-opacity-[50%] rounded-t-[30px] my-[60px]">
          <div className="py-[50px] sm:mx-[60px] mx-[30px]">
            <div>
              <h2 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                How to Join as GR Outpost?
              </h2>
            </div>
            <div className="grid sm:grid-cols-3 grid-cols-1 items-center gap-[60px] justify-center mt-[52px]">
              <div>
                <h1 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                  01.
                </h1>
                <div className="flex gap-[8px] items-center">
                  <h1 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                    Apply Now
                  </h1>
                  <img className="w-[35px] object-cover" src={plane} alt="" />
                </div>
              </div>
              <div>
                <div className="bg-[#FBC903] rounded-t-[30px] px-6 pt-6 pb-[50px]">
                  <h1 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                    02.
                  </h1>
                  <div className="flex gap-[8px] items-center">
                    <h1 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                      Get Vetted
                    </h1>
                    <img
                      className="w-[35px] object-cover"
                      src={magnify}
                      alt=""
                    />
                  </div>
                  <div>
                    <p>
                      Our team will review your application and get in touch.
                      We're seeking passionate, skilled tech experts ready to
                      make an impact.
                    </p>
                    <button className="flex items-center gap-[6px] mt-[30px]">
                      Learn More <GoArrowRight />
                    </button>
                  </div>
                </div>
                <button
                  className="bg-[#FBC903] mt-4 text-[#101828] font-semibold text-[16px] rounded-[16px] py-[10px] w-full"
                  onClick={() =>
                    (window.location.href =
                      "https://zfrmz.com/m3Z6nmdPqiGuGpEMR6Bv")
                  }
                >
                  Join now
                </button>
              </div>
              <div>
                <h1 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                  03.
                </h1>
                <div className="flex gap-[8px] items-center">
                  <h1 className="sm:text-[32px] text-[24px] text-[#101828] font-bold">
                    Train & Gear Up
                  </h1>
                  <img
                    className="w-[35px] object-cover"
                    src={superman}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GrOutpost;
