import React from "react";
import icon1 from "./styles/Icon.png";
import icon2 from "./styles/Icon(1).png";
import icon3 from "./styles/Icon(2).png";
const FeaturesRepair = () => {
  return (
    <div className="bg-grSilver py-[64px] font-grFont font-[400] rounded-gr flex items-center justify-center mt-12 sm:mx-0 mx-[20px]">
      <div className="flex flex-col gap-[60px] lg:gap-[150px] lg:flex-row self-center ml-[-16px]">
        <div className="flex flex-col gap-[10px]">
          <img src={icon1} className="h-[40px] w-[40px] mb-4"></img>
          <h2 className="font-[700] font-plus-jakarta-sans text-[24px] leading-[32px] ">
            Get a Quote
          </h2>
          <p className="text-[18px] font-plus-jakarta-sans leading-[28px] font-[400]">
            Choose the device you need <br /> repaired and receive competitive
            <br /> pricing estimation.
          </p>
        </div>
        <div className=" flex flex-col gap-[10px]">
          <img src={icon2} className="h-[40px] w-[40px] mb-4"></img>
          <h2 className="font-[700] font-plus-jakarta-sans text-[24px] leading-[32px]">
            Book a Repair Appointment
          </h2>
          <p className="text-[18px] font-plus-jakarta-sans leading-[28px] font-[400]">
            Schedule a convenient pick-up or <br /> drop-off time that works
            best for <br /> you.
          </p>
        </div>
        <div className="flex flex-col gap-[10px]">
          <img src={icon3} className="h-[40px] w-[40px] mb-4"></img>
          <h2 className="font-[700] font-plus-jakarta-sans text-[24px] leading-[32px]">
            Professional Gadget Repair
          </h2>
          <p className="text-[18px] font-plus-jakarta-sans leading-[28px] font-[400]">
            Our skilled technician will fix your <br /> gadget and breathe new
            life into it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeaturesRepair;
