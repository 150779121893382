import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import DefectCard from "./DefectCard";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Loader from "../loader/Loader";
import { toast, ToastContainer } from "react-toastify";

const DefectTwo = () => {
  const [primaryDefects, setPrimaryDefects] = useState([]);
  const [secondaryDefects, setSecondaryDefects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPrimary, setIsPrimary] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Set default category values based on the route
  useEffect(() => {
    const path = location.pathname;
    if (path === "/repair/mobile") {
      sessionStorage.setItem("categorySkuCode", "101");
      sessionStorage.setItem("categoryName", "Mobile");
    } else if (path === "/repair/tablet") {
      sessionStorage.setItem("categorySkuCode", "105");
      sessionStorage.setItem("categoryName", "Tablet");
    } else if (path === "/repair/laptop") {
      sessionStorage.setItem("categorySkuCode", "102");
      sessionStorage.setItem("categoryName", "Laptop");
    } else if (path === "/repair/playstation") {
      sessionStorage.setItem("categorySkuCode", "103");
      sessionStorage.setItem("categoryName", "Playstation");
    }
  }, [location.pathname]);

  // Get categorySkuCode from sessionStorage
  useEffect(() => {
    const categorySkuCode = sessionStorage.getItem("categorySkuCode");
  
    if (!categorySkuCode) {
      setLoading(false);
      setError("Category not set");
      return;
    }
  
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}:8070/master-service/deffect/find-by-category?categorySkucode=${categorySkuCode}`
        );
        const data = response?.data;
        // console.log(data);
        if (data) {
          const primaryDefects = data.filter((defect) => defect.isPrimary);
          const secondaryDefects = data.filter((defect) => !defect.isPrimary);
          setPrimaryDefects(primaryDefects);
          setSecondaryDefects(secondaryDefects);
        } else {
          throw new Error("No data returned from API");
        }
      } catch (error) {
        setError(error.message);
        toast.error("Failed to fetch defects.");
      } finally {
        setLoading(false);
      }
    }
  
    fetchData();
  }, [location.pathname]);

  const handleDefectClick = (defectSkuCode, name, description, imageUrl) => {
    sessionStorage.setItem("defectSkuCode", defectSkuCode);
    sessionStorage.setItem("defectName", name);
    navigate("/device-selection", { state: { name, description, imageUrl } });
  };

  if (loading) return <div><Loader loading={loading} /></div>;


  const handleGoBack = () => {
    navigate('/repair', { state: { ...location.state } });
  };

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <button
        onClick={handleGoBack}
        className="w-[110px] hidden h-9 px-4 py-2 sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
      >
        <MdKeyboardArrowLeft />
        <span className="font-bold text-sm leading-5">Go Back</span>
      </button>
      <div className="flex items-center mt-6">
        <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 sm:text-center text-left leading-10 sm:mx-0 mx-6">
          What would you like to fix?
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 mt-10 px-4 gap-y-4 gap-x-5">
        {isPrimary
          ? primaryDefects.map((defect, index) => (
              <DefectCard
                key={index}
                title={defect.name}
                description={defect.description}
                icon={defect.imageUrl}
                skuCode={defect.skuCode}
                onClick={handleDefectClick}
              />
            ))
          : secondaryDefects.map((defect, index) => (
              <DefectCard
                key={index}
                title={defect.name}
                description={defect.description}
                icon={defect.imageUrl}
                skuCode={defect.skuCode}
                onClick={handleDefectClick}
              />
            ))}
      </div>
    </div>
  );
  
};

export default DefectTwo;
