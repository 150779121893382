import React from 'react';

const steps = [
  { id: 1, label: 'Select Issue', isActive: false },
  { id: 2, label: 'Select Device', isActive: false },
  { id: 3, label: 'Get Estimation', isActive: false },
  { id: 4, label: 'Select Address', isActive: false },
  { id: 5, label: 'Confirm Booking', isActive: true }
];

const ScreenNav = () => {
  return (
    <div className="relative hidden lg:flex flex-col items-start  h-[300px] self-start">
      {steps.map((step, index) => (
        <div key={index} className="flex items-center relative mb-8">
          <div className="flex flex-col items-center relative">
            <div
              className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center shadow-none ${
                'border-[11px] border-yellow-500 bg-white'
              }`}
            >
              <div className="h-[25px] w-[25px] rounded-full bg-yellow-500 flex items-center justify-center text-white text-sm">✔</div>
            </div>
            {index < steps.length - 1 && (
              <div className="absolute top-full mt-1 h-8 w-px bg-yellow-500"></div>
            )}
          </div>
          <div className={`ml-4 text-lg steps font-semibold ${step.isActive ? 'text-[#344054]' : 'text-[#344054]'}`}>
            {step.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScreenNav;
