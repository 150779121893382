import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BookingFee from '../components/bookingFee/BookingFee';

const BookingFeePage = () => {
  const location = useLocation();
  // Safely extract estimatedPrice with default value
  const estimatedPrice = location.state?.estimatedPrice || null;

  // console.log('fixing error', estimatedPrice);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="sm:mx-[50px] ">
      <BookingFee estimatedPrice={estimatedPrice} />
    </div>
  );
};

export default BookingFeePage;
