import React from "react";
import { FadeLoader } from "react-spinners";

const Loader = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <FadeLoader loading={loading} size={50} color={"#fbc906"} />
    </div>
  );
};

export default Loader;
