import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function AboutUs() {
  return (
    <div>
      <div className="lg:mx-[40px]">
        <Navbar />
      </div>
      <div className="min-h-screen my-[40px]">
        <main className="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8">
          <section className="text-center mb-3">
            <h1 className="text-[38px] sm:text-[48px] font-semibold text-gray-900 text-start">
              About Us
            </h1>
          </section>

          <section className="space-y-6">
            <div>
              <h2 className="underline font-semibold">
                Welcome to Gadgets Reborn– Where We Care & Repair!
              </h2>
              <p className="mt-2">
                We believe that your gadgets are more than just devices– they
                are your lifelines. What happens when the warranty expires and
                suddenly your most treasured devices feel more vulnerable than
                ever? Got an emergency? Don’t panic, that is when you need a
                gadget doctor!
              </p>
              <p className="mt-2">
                So, if your devices are feeling ‘glitchy’ or need a routine
                check up, our Gadget Heroes are here to nurse your favourites
                back to good health. Your device is always in safe
                hands—literally!
              </p>
            </div>

            <div>
              <h2 className="underline font-semibold">
                Our Mission: Revive, Repair, Reborn!
              </h2>
              <p className="mt-2">
                Our dedication to giving your devices fresh life is at the core
                of what we do. In order to help you save time, money, and the
                environment, we believe in providing a streamlined, hassle-free
                experience that advocates repair over replacement. Along with
                reviving your device, we also rebuild your confidence in its
                functionality with each repair. Along with the piece of mind
                that comes with it, you'll leave with a device that feels nearly
                brand-new.
              </p>
            </div>

            <div>
              <h2 className="underline font-semibold">
                Shield, Safeguard and Secure
              </h2>
              <p className="mt-2">
                We provide your gadgets with strong shielding and protection so
                they stay in optimal condition. Our protection subscription
                plans offer dependable repairs and service for peace of mind. In
                addition, peruse our selection of safe, approved refurbished
                electronics and entrust us with all of your gadget requirements,
                both now and in the future.
              </p>
              <p className="mt-2 font-semibold">
                Our Vision: Repair is the New Reborn OR Save Gadgets, Save the
                Planet.
              </p>
              <p className="mt-2">
                We believe in repairing, not replacing—good for your wallet, and
                even better for the planet. Our mission goes beyond just
                reviving your devices; we're committed to fostering a
                sustainable future, proudly supporting the Right to Repair
                Movement and ensuring your gadgets stay in use rather than in
                landfills.
              </p>
            </div>

            <div>
              <h2 className="mt-2 underline font-semibold">
                Your Chief Gadget Surgeon
              </h2>
              <p className="mt-2">
                “Let’s face it—gadget issues can make anyone feel a little under
                the weather. But don’t worry, we specialise in treating all
                sorts of challenges you might face”- Anshuman Saxena,
                Co-Founder.
              </p>
              <p className="mt-2">
                With more than 20 years of experience in the device distribution
                business, he has seen firsthand how many devices are thrown out
                after their warranties expire, leaving customers dissatisfied
                and without a device. His remedy? Gadgets Reborn is synonymous
                to giving your handheld devices another go.
              </p>
              <p className="mt-2">
                Anshuman is passionate about sustainability and the Right to
                Repair Movement. He has dedicated his career to building a
                favourable ecosystem so that clients never have to worry about
                unscrupulous pricing, mediocre work, or data security.
              </p>
              <p className="mt-2">
                With Gadgets Reborn, you may not only prolong the life of your
                gadget but also benefit the environment in addition to fixing
                it.
              </p>
            </div>

            <div>
              <h2 className="mt-2 underline font-semibold">
                Sustainability at Our Core
              </h2>
              <p className="mt-2">
                Did you know that one of the waste streams with the fastest
                global growth is electronic garbage, or "e-waste"? The Global
                E-waste Monitor 2020 states that 53.6 million metric tonnes of
                e-waste were produced globally in 2019; by 2030, this amount is
                expected to rise to 74.7 million metric tonnes. Over the next
                ten years, e-waste will grow at a rate of 21%, emphasising the
                critical need for recycling and disposal methods that are
                sustainable.
              </p>
              <p className="mt-2">
                Source: Global E-waste Monitor 2020, International
                Telecommunication Union (ITU), United Nations University (UNU),
                and International Solid Waste Association (ISWA).
              </p>
              <p className="mt-2">
                We are contributing to its slowdown. Gadgets Reborn is dedicated
                to minimising e-waste and strengthening a more environment
                friendly and sustainable future by urging repairs rather than
                discarding.
              </p>
              <p className="mt-2 font-semibold">
                All Handheld Devices Welcome!
              </p>
              <p className="mt-2">
                No matter what handheld device you're using, Gadgets Reborn has
                the solution. Whether it's your beloved smartphone, tablet, or
                other gadget you've grown attached to, we’ve got the experience
                and expertise to solve your troubles.
              </p>
              <p className="mt-2 font-semibold">
                Repair with Confidence—Book Your Appointment Today!
              </p>
              <p className="mt-2">
                You can be sure that your gadgets are in the best possible hands
                when you use Gadgets Reborn. Bid farewell to concerns about your
                gadgets and welcome seamless solutions.
              </p>
              <p className="mt-2 underline font-semibold">
                Make an online appointment, and we'll handle the rest!
              </p>
            </div>
          </section>
        </main>
      </div>
      <div className="lg:mx-[40px]">
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
