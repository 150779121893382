import React, { useEffect, useState } from "react";
import axios from "axios";
import crossIcon from "./cross.png"; // Make sure to replace this with the actual path to your cross icon
import Loader from "../loader/Loader";

const BookingConfirmed = () => {
  const [repairBookingDetailsFinalPage, setRepairBookingDetailsFinalPage] =
    useState(null);
  const [addressResponse, setAddressResponse] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [defectDetails, setDefectDetails] = useState(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  const [serviceUuid, setServiceUuid] = useState(() => {
    const uuid = sessionStorage.getItem("serviceUuid");
    sessionStorage.removeItem("serviceUuid");
    return uuid;
  });

  useEffect(() => {
    const bookingId = new URLSearchParams(window.location.search).get(
      "booking_id"
    );
    if (!bookingId) {
      setIsError(true);
      setLoading(false); // Stop loading if there's an error
      return;
    }

    const fetchBookingDetails = async () => {
      const authToken = sessionStorage.getItem("authToken");
      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}:9009/payment-service/api/payment/details-repair`,
        params: { bookingId },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const { data } = await axios.request(options);
        console.log(data);
        setRepairBookingDetailsFinalPage(data.repairBookingDetailsFinalPage);
        setAddressResponse(data.addressResponse);
        setPaymentDetails(data.paymentResponseModified);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setLoading(false); // Stop loading after the API call
      }
    };

    fetchBookingDetails();
  }, []);

  useEffect(() => {
    const fetchDefectDetails = async () => {
      const options = {
        method: "GET",
        url: `https://apiv1.gadgetsreborn.com:8070/master-service/defect/${repairBookingDetailsFinalPage?.defectSkuCode}`,
        headers: { accept: "*/*" },
      };
      try {
        const { data } = await axios.request(options);
        setDefectDetails(data);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setLoading(false); // Stop loading after the API call
      }
    };

    if (repairBookingDetailsFinalPage?.defectSkuCode) {
      setLoading(true); // Start loading before the API call
      fetchDefectDetails();
    }
  }, [repairBookingDetailsFinalPage?.defectSkuCode]);

  // Determine the booking status based on paymentStatus
  const bookingStatus =
    paymentDetails?.paymentStatus === "CANCELLED"
      ? "Booking Cancelled"
      : "Booking Confirmed";
  const amountCollected =
    paymentDetails?.paymentStatus === "CANCELLED"
      ? 0
      : paymentDetails?.amountCollected;
  const totalAmount = paymentDetails?.totalAmount;

  return (
    <div className="mt-6 w-full">
      <Loader loading={loading} /> {/* Use the Loader component */}
      {isError ? (
        <div className="flex flex-col min-h-screen bg-[#F9FAFB] col-span-4 p-7 px-8">
          <img src={crossIcon} alt="Error Icon" className="w-16 h-16 mb-4" />
          <h2 className="text-2xl font-bold text-[#101828]">
            Invalid Booking ID
          </h2>
          <p className="text-gray-500 mt-2 text-left">
            We couldn't find a match for the Booking ID you entered. Please
            double-check the ID and try again.
          </p>
        </div>
      ) : (
        <>
          <h1 className="sm:text-4xl text-[28px] font-bold mb-2 text-[#101828]">
            {bookingStatus}
          </h1>
          {paymentDetails?.paymentStatus !== "CANCELLED" && (
            <p className="text-lg text-[#475467] mb-10">
              Please find the booking details attached below.
            </p>
          )}
          <div className="bg-white p-4 rounded-lg mb-6 border border-[#EAECF0]">
            <h2 className="text-2xl font-bold mb-4">Booking Fee Details</h2>
            {/* <p className="mb-1">
              <span className="text-base text-[#475467]">Payment status: </span>
              <span className="text-base font-semibold text-[#344054]">{paymentDetails?.paymentStatus || "N/A"}</span>
            </p> */}
            {/* <p className="mb-1">
              <span className="text-base text-[#475467]">
                Payment Transaction reference:{" "}
              </span>
              <span className="text-base font-semibold text-[#344054]">
                {paymentDetails?.transactionReference || "N/A"}
              </span>
            </p> */}
            <p className="mb-1">
              <span className="text-base text-[#475467]">Total Amount: </span>
              <span className="text-base font-semibold text-[#344054]">
                {totalAmount ? `AED ${totalAmount}` : ""}
              </span>
            </p>
            <p className="mb-1">
              <span className="text-base text-[#475467]">Discount: </span>
              <span className="text-base font-semibold text-[#344054]">
                {paymentDetails?.couponCode || "N/A"}
              </span>
            </p>
            <p className="mb-4">
              <span className="text-base text-[#475467]">
                Amount Collected:{" "}
              </span>
              <span className="text-base font-semibold text-[#344054]">
                {amountCollected} AED
                {/* {amountCollected ? `AED ${amountCollected}` : ""} */}
              </span>
            </p>
          </div>

          <div className="bg-white p-4 rounded-lg mb-6 border border-[#EAECF0]">
            <h2 className="text-2xl font-bold mb-4">Booking Details</h2>
            <p className="mb-1">
              <span className="text-base text-[#475467]">Booking ID: </span>
              <span className="text-base font-semibold text-[#344054]">
                {repairBookingDetailsFinalPage?.bookingId || "N/A"}
              </span>
            </p>
            <p className="mb-1">
              <span className="text-base text-[#475467]">Brand & Model: </span>
              <span className="text-base font-semibold text-[#344054]">
                {repairBookingDetailsFinalPage?.brandName || "N/A"} &{" "}
                {repairBookingDetailsFinalPage?.model}
              </span>
            </p>
            <p className="mb-1">
              <span className="text-base text-[#475467]">Category:</span>
              <span className="text-base font-semibold text-[#344054]">
                {repairBookingDetailsFinalPage?.categoryName || "N/A"}
              </span>
            </p>
            <p className="mb-1">
              <span className="text-base text-[#475467]">Defect name:</span>
              <span className="text-base font-semibold text-[#344054]">
                {repairBookingDetailsFinalPage?.defect || "N/A"}
              </span>
            </p>
            <p className="mb-4">
              <span className="text-base text-[#475467]">
                Defect Description:{" "}
              </span>
              <span className="text-base font-semibold text-[#344054]">
                {defectDetails?.description || "N/A"}
              </span>
            </p>
          </div>

          <div className="bg-white p-4 rounded-lg border border-[#EAECF0]">
            <h2 className="text-2xl font-bold mb-4">
              Address and Additional Information
            </h2>
            <p className="text-base font-medium text-[#475467]">
              {addressResponse?.name || "N/A"}
            </p>
            <p className="text-base font-medium text-[#475467] mb-1">
              {addressResponse?.email || "N/A"}
            </p>
            <p className="text-base font-medium text-[#475467] mb-1">
              {addressResponse?.phoneNumber || "N/A"}
            </p>
            <p className="mb-1 text-base text-[#475467] break-words">
              {addressResponse?.address || "N/A"}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingConfirmed;
