import React from "react";
import { SlArrowRight } from "react-icons/sl";

const Issue = ({ title, description, icon }) => {
  return (
    <div className="bg-[#FFFFFF] rounded-[12px] p-4 flex items-center border border-[#D0D5DD] w-full max-w-[350px] lg:max-w-none">
      <img src={icon} className="h-[40px] w-[40px] mr-4" alt="Issue Icon" />
      <div className="flex flex-col">
        <div className="font-bold text-[16px] lg:text-[20px] leading-[24px] text-[#344054]">
          {title}
        </div>
        <p className="text-[#475467] text-[12px] lg:text-[16px] leading-[18px] mt-1">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Issue;
